<template>
  <main class="app-content">
    <div class="app-title">
      <div>
        <h1>Notifications</h1>
        <p>
          Quick notes to your
          {{ user.admin > 0 ? "customer " : "Upstream rep" }}
        </p>
      </div>
      <ul class="app-breadcrumb breadcrumb">
        <li class="breadcrumb-item">
          <a href="#/user/dashboard"><i class="fa fa-home fa-lg"></i></a>
        </li>
        <li class="breadcrumb-item">
          <a href="#/user/dashboard">Dashboard</a>
        </li>
        <li class="breadcrumb-item">Notifications</li>
      </ul>
    </div>

    <div class="row">
      <div class="col-lg-3">
        <div class="tile p-0x note-folders">
          <h4 class="tile-title folder-head">Folders</h4>
          <div class="tile-body">
            <ul class="nav nav-pills flex-column mail-nav">
              <li class="nav-item active">
                <a class="nav-link" @click.prevent="selectFolder('inbox')"
                  ><i class="fa fa-inbox fa-fw"></i> Inbox<span
                    class="badge badge-pill badge-primary float-right"
                    >{{ inboxCount() }}</span
                  ></a
                >
              </li>
              <li class="nav-item">
                <a class="nav-link" @click.prevent="selectFolder('sent')"
                  ><i class="fa fa-envelope-o fa-fw"></i> Sent<span
                    class="badge badge-pill badge-primary float-right"
                    >{{ sentCount() }}</span
                  ></a
                >
              </li>
            </ul>
          </div>
          <div class="tile-footer">
            <ul class="nav nav-pills flex-column mail-nav">
              <li class="nav-item active">
                <a
                  class="nav-link"
                  href="#"
                  @click.prevent="selectFolder('compose')"
                  ><i
                    class="fa fa-envelope-open-o fa-fw"
                    aria-hidden="true"
                  ></i>
                  New Mail</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-9">
        <div v-if="folders === 'compose'" class="mailbox-compose">
          <div class="card">
            <h4 class="card-header d-flex align-items-center">
              <span class="app-notification__icon">
                <span class="fa-stack fa-lg"
                  ><i class="fa fa-circle fa-stack-2x text-info"></i
                  ><i class="fa fa-envelope fa-stack-1x fa-inverse"></i>
                </span>
              </span>
              <input
                class="form-control"
                type="text"
                placeholder="Subject"
                tabindex="1"
                v-model="newSubject"
              />
            </h4>

            <div class="card-body">
              <!-- <p class="card-text" v-html="openNote.message"></p> -->
              <tiptap
                :admin="user.admin"
                :userid="user._id"
                :account="user._id"
                :list="'user'"
                :csrf="csrf"
                :tabindex="2"
                :subject="newSubject"
                @emitSendMessage="sent"
                :ui="false"
              />
            </div>

            <div
              class="card-footer text-muted d-flex align-items-center justify-content-between"
            >
              <!-- {{ openNote.date }} -->
            </div>
          </div>
        </div>
        <div
          v-if="folders !== 'compose' && openNote && openNote.from !== ''"
          class="mailbox-window"
        >
          <div class="card">
            <h4 class="card-header">
              <span class="app-notification__icon">
                <span class="fa-stack fa-lg">
                  <i
                    :class="`fa fa-circle fa-stack-2x text-${getCol(
                      openNote.type
                    )}`"
                  ></i>
                  <i
                    :class="`fa fa-${getType(
                      openNote.type
                    )} fa-stack-1x fa-inverse`"
                  ></i>
                </span>
              </span>
              {{ openNote.subject || "" }}
            </h4>

            <div class="card-body">
              <p class="card-text" v-html="openNote.message"></p>
            </div>
            <!-- <div class="card-body">
              <h5 class="card-title"></h5>
              <h6 class="card-subtitle text-muted">
                From: {{ openNote.from || "" }}
              </h6>
            </div> -->
            <div
              class="card-footer text-muted d-flex align-items-center justify-content-between"
            >
              From: {{ openNote.from || "" }}<br />
              Date: {{ openNote.date }}
              <button
                class="btn btn-outline-secondary"
                type="button"
                @click="deleteNote(openNote._id)"
              >
                <i class="fa fa-trash-o"></i>
              </button>
            </div>
          </div>
        </div>

        <div class="tile">
          <h4 class="tile-title folder-head">
            {{ this.folders === "inbox" ? "Inbox" : "Sent" }}
          </h4>
          <!--<div class="mailbox-controls">
             <div class="animated-checkbox">
              <label>
                <input type="checkbox" /><span class="label-text"></span>
              </label>
            </div>
           <div class="btn-group">
              <button class="btn btn-primary btn-sm" type="button">
                <i class="fa fa-trash-o"></i>
              </button>
              <button class="btn btn-primary btn-sm" type="button">
                <i class="fa fa-reply"></i>
              </button>
              <button class="btn btn-primary btn-sm" type="button">
                <i class="fa fa-share"></i>
              </button>
              <button class="btn btn-primary btn-sm" type="button">
                <i class="fa fa-refresh"></i>
              </button>
            </div>
          </div> -->
          <div class="table-responsive mailbox-messages">
            <table class="table table-hover">
              <tbody>
                <!-- -->
                <tr v-if="showNoteList.length < 1">
                  <p class="m-2">No messages to display.</p>
                </tr>
                <tr v-for="(note, index) in showNoteList" :key="index">
                  <!-- <td>
                    <div class="animated-checkbox">
                      <label>
                        <input type="checkbox" /><span class="label-text">
                        </span>
                      </label>
                    </div>
                  </td> -->
                  <!-- <td>
                    <a href="#"><i class="fa fa-star-o"></i></a>
                  </td> -->
                  <td @click.prevent="showNotification(note._id)">
                    <span class="app-notification__icon">
                      <span class="fa-stack fa-lg">
                        <i
                          :class="`fa fa-circle fa-stack-2x text-${getCol(
                            note.type
                          )}`"
                        ></i>
                        <i
                          :class="`fa fa-${getType(
                            note.type
                          )} fa-stack-1x fa-inverse`"
                        ></i>
                      </span>
                    </span>
                  </td>
                  <td class="mail-subject" @click="showNotification(note._id)">
                    <b>{{ note.subject }}</b>
                    <br />
                    {{ note.from }}
                  </td>
                  <td @click.prevent="showNotification(note._id)">
                    {{ note.date }}
                  </td>
                  <td
                    @click.prevent="showNotification(note._id)"
                    v-if="folders === 'inbox'"
                  >
                    <i v-if="note.new" class="fa fa-star text-danger"></i>
                    <i v-else class="fa fa-star-o"></i>
                  </td>
                  <td v-if="folders !== 'inbox'">
                    <i class="fa fa-paper-plane"></i>
                  </td>
                </tr>
                <!-- <tr v-if="user.notes">
                  There are no messages.
                </tr> -->
                <!-- -->
              </tbody>
            </table>
          </div>
          <div class="text-right">
            <!-- <span class="text-muted mr-2">Showing 0 of 0</span>
            <div class="btn-group">
              <button class="btn btn-primary btn-sm" type="button">
                <i class="fa fa-chevron-left"></i>
              </button>
              <button class="btn btn-primary btn-sm" type="button">
                <i class="fa fa-chevron-right"></i>
              </button>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import Tiptap from "@//components/Tiptap.vue";
import Swal from "sweetalert2";
export default {
  name: "Content",
  components: {
    Tiptap,
  },

  data() {
    return {
      openNote: {
        subject: "",
        message: "",
        date: "",
        from: "",
        new: false,
      },
      folders: "inbox",
      newSubject: "",
      newNotes: 0,
      showNoteList: [],
      account: {
        firstname: "",
        lastname: "",
        email: "",
        notifications: [],
      },
    };
  },
  computed: {
    ...mapState(["user", "csrf", "customers", "accountmanager"]),
    ...mapGetters(["getCustomer"]),
  },
  watch: {
    user() {
      this.selectFolder(this.folders);
    },
  },
  mounted() {
    if (this.user.admin == 0 && !Object.keys(this.accountmanager).length) {
      this.$store.dispatch("GETADMINMANAGER").then((response) => {
        this.log("Customer:#06f", "Get Admin success", response);
      });
    }
    // console.log(this.user.unread);
    this.selectFolder("inbox");
    console.log(this.user);
    console.log(this.accountmanager);
  },
  methods: {
    getType(type) {
      switch (type) {
        case "order":
          return "shopping-basket";
        default:
          return "envelope";
      }
    },
    getCol(type) {
      switch (type) {
        case "order":
          return "success";
        default:
          return "info";
      }
    },
    inboxCount() {
      return this.user.notifications?.userinbox?.length || 0;
    },
    sentCount() {
      return this.user.notifications?.usersent?.length || 0;
    },
    sent() {
      this.newSubject = "";
    },
    selectUser(event) {
      console.log(event.target.value);
      if (!event.target.value) {
        this.account = {
          firstname: "",
          lastname: "",
          email: "",
          notifications: [],
        };
      } else {
        this.account = this.getCustomer(event.target.value);
        console.log(this.account);
      }
    },
    selectFolder(target) {
      this.folders = target;
      console.log(target);
      this.showNoteList = [];
      this.openNote = {
        subject: "",
        message: "",
        date: "",
        from: "",
        new: false,
      };
      if (
        target === "inbox" &&
        this.user.notifications?.userinbox?.length >= 1
      ) {
        this.showNoteList = this.user.notifications.userinbox;
        this.openNote = this.showNoteList[0];
      }
      if (
        target !== "inbox" &&
        this.user.notifications?.usersent?.length >= 1
      ) {
        this.showNoteList = this.user.notifications.usersent;
        this.openNote = this.showNoteList[0];
      }
    },
    showNotification(id) {
      this.openNote = this.showNoteList.find((x) => x._id === id);
      console.log(this.openNote);
      this.folders = this.folders === "inbox" ? "inbox" : "sent";
      if (this.folders === "inbox" && this.openNote.new) {
        this.$store
          .dispatch("UPDATEUNREAD", {
            noteId: id,
            list: "user",
            account: this.user.id,
            folder: this.folders,
          })
          .then((response) => {
            this.log("TipTap:amber", response);
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    },
    deleteNote(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Your message will be deleted.",
        icon: "warning",
        showDenyButton: true,
        showConfirmButton: true,
        denyButtonText: "No, cancel please",
        confirmButtonText: "Yes, delete message",
        confirmButtonColor: "#218838",
      }).then((response) => {
        if (response.isConfirmed) {
          console.log(id);

          this.$store
            .dispatch("DELETENOTIFICATION", {
              noteId: id,
              list: "user",
              account: this.user._id,
              folder: this.folders,
            })
            .then((response) => {
              console.log("Delete notification", response);
              this.successSwal("Your message has been deleted.");
              this.openNote = {
                subject: "",
                message: "",
                date: "",
                from: "",
                new: false,
              };
            })
            .catch((errors) => {
              console.log("Cannot delete", errors);
              this.errorSwal();
            });
        } else {
          this.cancelledSwal("No messages have been removed.");
        }
      });
    },
  },
};
</script>
<style lang="css">
.tile {
  overflow: hidden;
}
</style>
