<template>
  <div v-if="editor && ui" class="editor-menu">
    <div class="btn-group btn-group-toggle" data-toggle="buttons">
      <button
        @click="editor.chain().focus().toggleBold().run()"
        :class="({ 'is-active': editor.isActive('bold') }, 'btn btn-primary')"
      >
        <i class="fa fa-bold" aria-hidden="true"></i>
      </button>
      <button
        @click="editor.chain().focus().toggleItalic().run()"
        :class="({ 'is-active': editor.isActive('italic') }, 'btn btn-primary')"
      >
        <i class="fa fa-italic" aria-hidden="true"></i>
      </button>
      <!-- <button
      @click="editor.chain().focus().toggleStrike().run()"
      :class="{ 'is-active': editor.isActive('strike') }"
    >
      strike
    </button> -->
      <!-- <button
      @click="editor.chain().focus().toggleCode().run()"
      :class="{ 'is-active': editor.isActive('code') }"
    >
      code
    </button> -->
      <!-- <button @click="editor.chain().focus().unsetAllMarks().run()">
      clear marks
    </button> -->
      <!-- <button @click="editor.chain().focus().clearNodes().run()">
      clear nodes
    </button> -->
      <button
        @click="editor.chain().focus().setParagraph().run()"
        :class="
          ({ 'is-active': editor.isActive('paragraph') }, 'btn btn-primary')
        "
      >
        <i class="fa fa-paragraph" aria-hidden="true"></i>
      </button>
      <!-- <button
      @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
      :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
    >
      h1
    </button> -->
      <button
        @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
        :class="
          ({ 'is-active': editor.isActive('heading', { level: 2 }) },
          'btn btn-primary')
        "
      >
        h2
      </button>
      <button
        @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
        :class="
          ({ 'is-active': editor.isActive('heading', { level: 3 }) },
          'btn btn-primary')
        "
      >
        h3
      </button>
      <button
        @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
        :class="
          ({ 'is-active': editor.isActive('heading', { level: 4 }) },
          'btn btn-primary')
        "
      >
        h4
      </button>
      <!-- <button
      @click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
      :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }"
    >
      h5
    </button> -->
      <!-- <button
      @click="editor.chain().focus().toggleHeading({ level: 6 }).run()"
      :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }"
    >
      h6
    </button> -->
      <button
        @click="editor.chain().focus().toggleBulletList().run()"
        :class="
          ({ 'is-active': editor.isActive('bulletList') }, 'btn btn-primary')
        "
      >
        <i class="fa fa-list-ul" aria-hidden="true"></i>
      </button>
      <button
        @click="editor.chain().focus().toggleOrderedList().run()"
        :class="
          ({ 'is-active': editor.isActive('orderedList') }, 'btn btn-primary')
        "
      >
        <i class="fa fa-list-ol" aria-hidden="true"></i>
      </button>
      <!-- <button
      @click="editor.chain().focus().toggleCodeBlock().run()"
      :class="{ 'is-active': editor.isActive('codeBlock') }"
    >
      code block
    </button> -->
      <!-- <button
      @click="editor.chain().focus().toggleBlockquote().run()"
      :class="{ 'is-active': editor.isActive('blockquote') }"
    >
      blockquote
    </button> -->
      <button
        @click="editor.chain().focus().setHorizontalRule().run()"
        class="btn btn-primary"
      >
        horizontal rule
      </button>
    </div>
    <!-- <button @click="editor.chain().focus().setHardBreak().run()">
      hard break
    </button> -->
    <!-- <button @click="editor.chain().focus().undo().run()">undo</button>
    <button @click="editor.chain().focus().redo().run()">redo</button> -->
  </div>
  <editor-content ref="editor" :editor="editor" class="editor-window" />
  <button
    v-if="!buttonMode"
    @click="sendMessage"
    class="btn btn-primary"
    :tabindex="tabindex + 1"
  >
    Send
  </button>
</template>

<script>
import { mapState } from "vuex";
import { Editor, EditorContent } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
export default {
  emits: ["emitSendMessage", "update:modelValue", "emitCopyUpdate"],
  components: {
    EditorContent,
  },
  computed: {
    ...mapState(["accountmanager"]),
  },
  props: {
    // modelValue: {
    //   type: String,
    //   default: "Your message.",
    // },
    admin: {
      type: Number,
    },
    userid: {
      type: String,
    },
    csrf: {
      type: String,
    },
    tabindex: {
      type: Number,
    },
    subject: {
      type: String,
    },
    ui: {
      type: Boolean,
    },
    account: {
      type: String,
    },
    list: {
      type: String,
    },
    hidebutton: {
      type: Boolean,
    },
    currentText: {
      type: String,
    },
  },

  data() {
    return {
      editor: null,
      htmlmessage1: "",
      // modelValue: "Test",
      buttonMode: false,
    };
  },

  watch: {
    // modelValue(value) {
    //   const isSame = this.editor.getHTML() === value;
    //   if (isSame) {
    //     return;
    //   }
    //   this.editor.commands.setContent(this.modelValue, false);
    // },
  },

  mounted() {
    console.log(this.currentText);
    // console.log(this.hidebutton);
    if (this.hidebutton) this.buttonMode = this.hidebutton;
    console.log(this.accountmanager);
    this.editor = new Editor({
      content: this.currentText,
      extensions: [StarterKit],
      editorProps: {
        attributes: {
          tabindex: this.tabindex,
        },
      },
      onUpdate: () => {
        // this.$emit("update:modelValue", this.editor.getHTML());
        this.htmlmessage1 = this.editor.getHTML();
        // console.log(this.htmlmessage1);
        this.$emit("emitCopyUpdate", this.htmlmessage1);
      },
    });
  },

  beforeUnmount() {
    this.editor.destroy();
  },
  methods: {
    sendMessage() {
      console.log("Sending message to user");
      this.$emit("emitSendMessage");

      let data = {
        message: {
          subject: this.subject,
          text: this.htmlmessage1,
          type: "message",
        },
        _csrf: this.csrf,
        userid: this.userid,
        account: this.account,
        list: this.list,
      };
      console.log(data);

      this.$store
        .dispatch("SENDNOTIFICATION", data)
        .then((response) => {
          this.log("TipTap:amber", response);
          this.htmlmessage1 = "";
          this.editor.commands.clearContent(true);
          this.success(
            "Success",
            "Your message has been sent" +
              (this.admin === 0 ? " to " + this.accountmanager.firstname : "")
          );
        })
        .catch((error) => {
          console.log(error);
          this.error("Warning", error.response);
          // Add a redirect here to resert the CSRF or login again.
        });
    },
  },
};
</script>

<style lang="scss">
.ProseMirror {
  padding: 25px 15px;
  border: solid 1px #ccc;
}
</style>
