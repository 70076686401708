<template>
  <main class="app-content">
    <div class="app-title">
      <div>
        <h1>Admin List</h1>
        <p>{{ user.firstname }} {{ user.lastname }} - {{ user.email }}</p>
      </div>
      <ul class="app-breadcrumb breadcrumb">
        <li class="breadcrumb-item">
          <a href="#/user/dashboard"><i class="fa fa-home fa-lg"></i></a>
        </li>
        <li class="breadcrumb-item">
          <a href="#/user/dashboard">Dashboard</a>
        </li>
        <li class="breadcrumb-item">Admins</li>
      </ul>
    </div>

    <!-- <div class="row">
      <div class="col-12">
        <div class="tile">
          <h2 class="mb-3 line-head" id="typography">New User Registration</h2>
        </div>
      </div>
    </div> -->

    <div class="row">
      <div class="col-12">
        <div class="tile">
          <h2 class="mb-3 line-head" id="typography">Admins</h2>

          <table-lite
            class="table-lite"
            :is-static-mode="true"
            :columns="table.columns"
            :rows="table.rows"
            :total="table.totalRecordCount"
            :sortable="table.sortable"
          >
            <!-- <template v-slot:view="data">
              <a
                :href="`#/user/customer/${data.value.view}`"
                class="btn btn-primary btn-user"
                type="button"
              >
                View / Edit
              </a>
            </template> -->
          </table-lite>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
// import api from "@/lib/api";:is-static-mode="true"
import { mapState } from "vuex";
import VueTableLite from "vue3-table-lite";
// import { defineComponent } from "vue";

export default {
  name: "Customers",
  components: {
    "table-lite": VueTableLite,
  },
  data() {
    return {
      data: {
        name: "",
      },
      table: {
        isLoading: false,
        columns: [
          {
            label: "Customer",
            field: "name",
            width: "10%",
            sortable: true,
            isKey: true,
          },
          {
            label: "Email",
            field: "email",
            width: "20%",
            sortable: true,
          },
          {
            label: "Phone",
            field: "phone",
            width: "10%",
            sortable: true,
            // display: (row) => {
            //   return `<b class="${row.status}">${row.status}</b>`;
            // },
          },
          {
            label: "Company",
            field: "company",
            width: "20%",
            sortable: true,
          },
          {
            label: "Inbox",
            field: "note",
            width: "10%",
            sortable: true,
            display: (row) => {
              return `${row.note} <i class="${
                row.note === 0 ? "fa fa-star-o" : "fa fa-star text-danger"
              }"></i>`;
            },
          },
          {
            label: "Cart",
            field: "cart",
            width: "10%",
            sortable: true,
          },
          {
            label: "Manager",
            field: "rep",
            width: "10%",
            sortable: true,
            display: (row) => {
              if (row.rep === "New User") {
                return `<b>New User</b>`;
              } else {
                return row.rep;
              }
            },
          },
          // {
          //   label: "",
          //   field: "view",
          //   width: "10%",
          //   // sortable: true,
          // },
          {
            label: "",
            field: "view",
            width: "10%",
            display: (row) => {
              return `
              <a style="width: 100%;" class="btn ${
                row.view.status === "New User" && row.view.admin === 0
                  ? "btn-success"
                  : "btn-primary"
              } btn-user" type="button" href="#/user/customer/${row.view.id}">
                ${
                  row.view.status === "New User" && row.view.admin === 0
                    ? "Assign Manager & Approve"
                    : "View / Edit"
                }
              </a>`;
            },
          },
        ],
        rows: [],
        totalRecordCount: 0,
        sortable: {
          order: "name",
          sort: "desc",
        },
      },
    };
  },
  computed: {
    ...mapState(["user", "csrf", "customers"]),
  },
  mounted() {
    this.log("Customers:#94f", "page");
    setTimeout(() => {
      this.lodeData();
    }, 100);
  },
  methods: {
    viewCustomer(UserID) {
      console.log("UserID = " + UserID);
    },
    lodeData() {
      const data = this.customers
        .filter((x) => x.admin > 0)
        .map((x) => {
          return {
            name: x.firstname + " " + x.lastname,
            email: x.email,
            phone: x.phone,
            company: x.company,
            cart: x.cart.length > 0 ? x.cart.length + " items" : "",
            rep: this.getManager(x.accountmanager),
            note: x.notifications.admininbox.filter((x) => x.new).length,
            view: {
              id: x._id,
              status: this.getManager(x.accountmanager),
              admin: x.admin,
            },
          };
        });
      console.log(data);
      this.table.rows = data;
      this.table.totalRecordCount = this.table.rows.length;
      this.table.sortable.order = "name";
      this.table.sortable.sort = "desc";
    },
    getManager(mid) {
      if (this.user.admin === 2) {
        this.log("Customers:#901", "id", mid);
        const manager = this.customers.find((x) => x.id === mid);
        this.log("Customers:#901", "manager", manager);
        const label =
          mid && manager.admin === 0 ? "New User" : "Account Manager";
        return mid ? manager.firstname + " " + manager.lastname : label;
      } else {
        return this.user.firstname + " " + this.user.lastname;
      }
    },
  },
};
</script>
