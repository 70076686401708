<template>
  <div class="tile full favourites d-flex flex-column">
    <h3 class="tile-title">Customer list</h3>
    <table class="table">
      <tbody v-if="customers.length > 0">
        <tr v-for="(customer, index) in customers.slice(0, 5)" :key="index">
          <td>
            <p class="text-left">
              {{ customer.firstname }} {{ customer.lastname }}
            </p>
          </td>
          <td>
            <p class="text-left">
              {{ customer.email }}
            </p>
          </td>
          <td>
            <p class="text-left">
              {{ customer.phone }}
            </p>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td>
            <p class="text-center no-samples">
              You have no customers assigned.
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <a :href="`#/user/customers`" v-if="customers.length > 0">
      <button class="btn btn-primary btn-block" type="button">
        See all customers
      </button>
    </a>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "Content",
  components: {},
  data() {
    return {
      htmlmessage: "",
      heartListDisplay: [],
      file: "",
      customerList: [],
    };
  },
  computed: {
    ...mapState(["user", "heartsObjects", "customers"]),
    // ...mapGetters(["getHartsAsObject"]),
  },
  watch: {
    customers() {
      console.log("Customers updated");
    },
  },
  mounted() {
    // this.$store.dispatch("GETHEARTSINDB").then(() => {
    //   console.log("Fav heart list updated");
    // });
    // this.customerList = this.customers;
  },
  methods: {
    getImage(list) {
      return list.split(",")[0];
    },
  },
};
</script>
