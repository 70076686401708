<template>
  <main class="app-content customer-page">
    <div class="app-title">
      <div>
        <h1>
          <i class="fa fa-dashboard"></i>
          <span v-if="user.admin === 1"> Admin account management</span>
          <span v-if="user.admin === 2"> Super Admin account management</span>
          <span v-else> User Account Details</span>
        </h1>
        <p>Update your account details and password.</p>
      </div>
      <ul class="app-breadcrumb breadcrumb">
        <li class="breadcrumb-item">
          <a href="#/user/dashboard"
            ><a href="#/user/dashboard"><i class="fa fa-home fa-lg"></i></a
          ></a>
        </li>
        <li class="breadcrumb-item">
          <a href="#/user/dashboard">Dashboard</a>
        </li>
        <li class="breadcrumb-item">
          <a href="javascript:;" onclick="history.back()">Accounts</a>
        </li>
        <li class="breadcrumb-item">
          {{ account.firstname }} {{ account.lastname }}
        </li>
      </ul>
    </div>
    <div v-if="!account.admin && pageTab != ''" class="row">
      <div class="col-md-4">
        <div
          :class="`account-btn widget-small primary-dark coloured-icon ${
            pageTab === 'note' ? 'success' : ''
          }`"
          @click="showTab('note')"
        >
          <i class="icon fa fa-bell fa-3x"></i>
          <div class="info">
            <h4>Notifications</h4>
            <p>Customer communications.</p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div
          :class="`account-btn widget-small primary-dark coloured-icon ${
            pageTab === 'order' ? 'success' : ''
          }`"
          @click="showTab('order')"
        >
          <i class="icon fa fa-shopping-basket fa-3x"></i>
          <div class="info">
            <h4>Orders / Cart</h4>
            <p>Customer orders and cart items.</p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div
          :class="`account-btn widget-small primary-dark coloured-icon ${
            pageTab === 'account' ? 'success' : ''
          }`"
          @click="showTab('account')"
        >
          <i class="icon fa fa-user fa-3x"></i>
          <div class="info">
            <h4>Account</h4>
            <p>Customer account records.</p>
          </div>
        </div>
      </div>
    </div>

    <div :class="`row ${pageTab === 'account' ? 'tab-visible' : 'tab-hidden'}`">
      <div class="col-lg-6">
        <div class="tile full full">
          <h2 v-if="!account.admin" class="mb-3 line-head" id="typography">
            {{ accountid ? "Customer" : "User" }} Details
          </h2>
          <h2 v-else class="mb-3 line-head">Admin Details</h2>
          <p class="text-muted">Please ensure details are always up to date.</p>
          <form class="form" id="account-update">
            <table v-if="account.firstname" class="table">
              <tbody>
                <tr>
                  <td><h5>First name</h5></td>
                  <td>
                    <input
                      class="form-control"
                      id="InputFirstName"
                      type="text"
                      required=""
                      name="firstname"
                      aria-describedby=""
                      placeholder="First name"
                      v-model="account.firstname"
                    />
                  </td>
                </tr>
                <tr>
                  <td><h5>Last name</h5></td>
                  <td>
                    <input
                      class="form-control"
                      id="InputLastName"
                      type="text"
                      required=""
                      name="lastname"
                      aria-describedby=""
                      placeholder="Last name"
                      v-model="account.lastname"
                    />
                  </td>
                </tr>
                <tr>
                  <td><h5>Email</h5></td>
                  <td>
                    <input
                      class="form-control"
                      id="InputEmail"
                      type="email"
                      required=""
                      name="email"
                      aria-describedby=""
                      placeholder="Email"
                      v-model="account.email"
                    />
                  </td>
                </tr>
                <tr>
                  <td><h5>Phone</h5></td>
                  <td>
                    <input
                      class="form-control"
                      id="InputPhone"
                      type="phone"
                      required=""
                      name="phone"
                      aria-describedby=""
                      placeholder="Phone No."
                      v-model="account.phone"
                    />
                  </td>
                </tr>
                <tr>
                  <td><h5>Company</h5></td>
                  <td>
                    <input
                      class="form-control"
                      id="InputCompany"
                      type="company"
                      required=""
                      name="company"
                      aria-describedby=""
                      placeholder="Company name"
                      v-model="account.company"
                    />
                  </td>
                </tr>
                <tr>
                  <td><h5>Job title</h5></td>
                  <td>
                    <input
                      class="form-control"
                      id="InputJob"
                      type="job"
                      required=""
                      name="job"
                      aria-describedby=""
                      placeholder="Job title"
                      v-model="account.job"
                    />
                  </td>
                </tr>
                <!-- -->

                <tr>
                  <td><h5>Street</h5></td>
                  <td>
                    <input
                      class="form-control"
                      id="InputStreet"
                      type="text"
                      required=""
                      name="street"
                      aria-describedby=""
                      placeholder="Street"
                      v-model="account.street"
                    />
                  </td>
                </tr>
                <tr>
                  <td><h5>Suburb</h5></td>
                  <td>
                    <input
                      class="form-control"
                      id="InputState"
                      type="text"
                      required=""
                      name="suburb"
                      aria-describedby=""
                      placeholder="Suburb"
                      v-model="account.suburb"
                    />
                  </td>
                </tr>
                <tr>
                  <td><h5>Postcode</h5></td>
                  <td>
                    <input
                      class="form-control"
                      id="InputPostcode"
                      type="text"
                      required=""
                      name="postcode"
                      aria-describedby=""
                      placeholder="Postcode"
                      v-model="account.postcode"
                    />
                  </td>
                </tr>
                <tr>
                  <td><h5>State</h5></td>
                  <td>
                    <input
                      class="form-control"
                      id="InputState"
                      type="text"
                      required=""
                      name="state"
                      aria-describedby=""
                      placeholder="State"
                      v-model="account.state"
                    />
                  </td>
                </tr>

                <!-- -->
                <tr v-if="!accountid">
                  <td><h5>Notes</h5></td>
                  <td>
                    <textarea
                      class="form-control"
                      id="InputNotes"
                      type="notes"
                      cols="30"
                      rows="10"
                      required=""
                      name="notes"
                      aria-describedby=""
                      placeholder="Personal notes"
                      v-model="account.notes"
                    ></textarea>
                  </td>
                </tr>
              </tbody>
            </table>
            <button
              class="btn btn-block btn-success mb-3"
              id="save-account"
              type="submit"
              @click.prevent="updateAccountDetails"
            >
              Save account details
            </button>
          </form>
        </div>
      </div>
      <div class="col-lg-6">
        <div v-if="accountid" class="tile full">
          <form class="form" id="admin-details">
            <h2 class="mb-3 line-head" id="typography">
              <span v-if="!account.admin">Customer </span>Admin records
            </h2>
            <p class="text-muted">
              Please ensure details are always up to date.<br />
            </p>
            <div
              v-if="!account.accountmanager && !account.admin"
              class="bs-component"
            >
              <div class="alert alert-dismissible alert-warning">
                <strong>New User!</strong><br />Assign this user a Manager to
                approve the registration and send the Welcome email.
              </div>
            </div>
            <table class="table">
              <tbody>
                <tr v-if="!account.admin">
                  <td>
                    <h5>Manager</h5>
                  </td>
                  <td v-if="user.admin === 2">
                    <select
                      class="form-control"
                      id="manager"
                      v-model="account.accountmanager"
                    >
                      <option disabled value="">Please select one</option>
                      <option
                        v-for="(manager, index) in customers.filter(
                          (x) => x.admin !== 0
                        )"
                        :key="index"
                        :value="manager._id"
                      >
                        {{ manager.firstname }} {{ manager.lastname }}
                      </option>
                    </select>
                  </td>
                  <td v-if="user.admin === 1">
                    {{ user.firstname }} {{ user.lastname }}
                  </td>
                </tr>
                <tr>
                  <td><h5>Customer notes</h5></td>
                  <td>
                    <textarea
                      class="form-control"
                      id="InputNotes"
                      type="notes"
                      cols="30"
                      rows="10"
                      required=""
                      name="notes"
                      aria-describedby=""
                      placeholder="Personal notes"
                      v-model="account.notesadmin"
                    ></textarea>
                  </td>
                </tr>
              </tbody>
            </table>
            <button
              class="btn btn-block btn-warning mb-3"
              id="save-password"
              type="button"
              @click.prevent="updateAccountDetails"
            >
              Update admin records
            </button>
          </form>
        </div>
      </div>
    </div>
    <!-- END ROW -->

    <!--?-->

    <div :class="`row ${pageTab === 'note' ? 'tab-visible' : 'tab-hidden'}`">
      <div class="col-lg-3">
        <div class="tile p-0x note-folders">
          <h4 class="tile-title folder-head">Folders</h4>
          <div class="tile-body">
            <ul class="nav nav-pills flex-column mail-nav">
              <li class="nav-item active">
                <a class="nav-link" @click.prevent="selectFolder('inbox')"
                  ><i class="fa fa-inbox fa-fw"></i> Inbox<span
                    class="badge badge-pill badge-primary float-right"
                    >{{ inboxCount() }}</span
                  ></a
                >
              </li>
              <li class="nav-item">
                <a class="nav-link" @click.prevent="selectFolder('sent')"
                  ><i class="fa fa-envelope-o fa-fw"></i> Sent<span
                    class="badge badge-pill badge-primary float-right"
                    >{{ sentCount() }}</span
                  ></a
                >
              </li>
            </ul>
          </div>
          <div class="tile-footer">
            <ul class="nav nav-pills flex-column mail-nav">
              <li class="nav-item active">
                <a
                  class="nav-link"
                  href="#"
                  @click.prevent="selectFolder('compose')"
                  ><i
                    class="fa fa-envelope-open-o fa-fw"
                    aria-hidden="true"
                  ></i>
                  New Mail</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-9">
        <div v-if="folders === 'compose'" class="mailbox-compose">
          <div class="card">
            <h4 class="card-header d-flex align-items-center">
              <span class="app-notification__icon">
                <span class="fa-stack fa-lg"
                  ><i class="fa fa-circle fa-stack-2x text-info"></i
                  ><i class="fa fa-envelope fa-stack-1x fa-inverse"></i>
                </span>
              </span>
              <input
                class="form-control"
                type="text"
                placeholder="Subject"
                tabindex="1"
                v-model="newSubject"
              />
            </h4>

            <div class="card-body">
              <!-- <p class="card-text" v-html="openNote.message"></p> -->
              <tiptap
                :userid="user._id"
                :account="accountid"
                :list="'admin'"
                :csrf="csrf"
                :tabindex="2"
                :subject="newSubject"
                @emitSendMessage="sent"
                :ui="false"
              />
            </div>

            <div
              class="card-footer text-muted d-flex align-items-center justify-content-between"
            >
              <!-- {{ openNote.date }} -->
            </div>
          </div>
        </div>
        <div
          v-if="folders !== 'compose' && openNote && openNote.from !== ''"
          class="mailbox-window"
        >
          <div class="card">
            <h4 class="card-header">
              <span class="app-notification__icon">
                <span class="fa-stack fa-lg">
                  <i
                    :class="`fa fa-circle fa-stack-2x text-${getCol(
                      openNote.type
                    )}`"
                  ></i>
                  <i
                    :class="`fa fa-${getType(
                      openNote.type
                    )} fa-stack-1x fa-inverse`"
                  ></i>
                </span>
              </span>
              {{ openNote.subject || "" }}
            </h4>

            <div class="card-body">
              <p class="card-text" v-html="openNote.message"></p>
            </div>

            <div
              class="card-footer text-muted d-flex align-items-center justify-content-between"
            >
              From: {{ openNote.from || "" }}<br />
              Date: {{ openNote.date }}
              <button
                class="btn btn-outline-secondary"
                type="button"
                @click="deleteNote(openNote._id)"
              >
                <i class="fa fa-trash-o"></i>
              </button>
            </div>
          </div>
        </div>

        <div class="tile">
          <h4 class="tile-title folder-head">
            {{ this.folders === "inbox" ? "Inbox" : "Sent" }}
          </h4>
          <div class="table-responsive mailbox-messages">
            <table class="table table-hover">
              <tbody>
                <tr v-if="showNoteList.length < 1">
                  <p class="m-2">No messages to display.</p>
                </tr>
                <tr v-for="(note, index) in showNoteList" :key="index">
                  <td @click.prevent="showNotification(note._id)">
                    <span class="app-notification__icon">
                      <span class="fa-stack fa-lg">
                        <i
                          :class="`fa fa-circle fa-stack-2x text-${getCol(
                            note.type
                          )}`"
                        ></i>
                        <i
                          :class="`fa fa-${getType(
                            note.type
                          )} fa-stack-1x fa-inverse`"
                        ></i>
                      </span>
                    </span>
                  </td>
                  <td class="mail-subject" @click="showNotification(note._id)">
                    <b>{{ note.subject }}</b>
                    <br />
                    {{ note.from }}
                  </td>
                  <td @click.prevent="showNotification(note._id)">
                    {{ note.date }}
                  </td>
                  <td
                    @click.prevent="showNotification(note._id)"
                    v-if="folders === 'inbox'"
                  >
                    <i v-if="note.new" class="fa fa-star text-danger"></i>
                    <i v-else class="fa fa-star-o"></i>
                  </td>
                  <td v-if="folders !== 'inbox'">
                    <i class="fa fa-paper-plane"></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!--?-->

    <div :class="`row ${pageTab === 'order' ? 'tab-visible' : 'tab-hidden'}`">
      <div class="col">
        <div class="tile full full">
          <h2 class="mb-3 line-head" id="typography">Customer cart</h2>
          <p class="text-muted">Customer shopping cart pending submission.</p>
          <table class="table table-cart">
            <tr v-for="(item, index) in account.cart" :key="index">
              <td class="table-prod">Product:</td>
              <td>
                <strong>{{ item.ordercode }}</strong> | {{ item.ordername }} |
                {{ item.ordercountry }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div :class="`row ${pageTab === 'order' ? 'tab-visible' : 'tab-hidden'}`">
      <div class="col">
        <div class="tile full full">
          <h2 class="mb-3 line-head" id="typography">Customer requests</h2>
          <p class="text-muted">Customer resquest and order history.</p>

          <table-lite
            class="table-lite"
            :key="listKey"
            :is-slot-mode="true"
            :columns="table.columns"
            :rows="table.rows"
            :total="table.totalRecordCount"
            :sortable="table.sortable"
          >
            <template v-slot:status="data">
              <select
                :class="`form-control form-${data.value.status.status}`"
                id="exampleSelect1"
                @change="onChange"
              >
                <option
                  :selected="data.value.status.status === 'Pending'"
                  :value="`${data.value.status.id}|Pending`"
                >
                  Pending
                </option>
                <option
                  :selected="data.value.status.status === 'Processing'"
                  :value="`${data.value.status.id}|Processing`"
                >
                  Processing
                </option>
                <option
                  :selected="data.value.status.status === 'Complete'"
                  :value="`${data.value.status.id}|Complete`"
                >
                  Complete
                </option>
              </select>
            </template>
          </table-lite>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import Tiptap from "@//components/Tiptap.vue";
import Swal from "sweetalert2";
import VueTableLite from "vue3-table-lite";
export default {
  name: "Customer",
  components: {
    Tiptap,
    "table-lite": VueTableLite,
  },

  data() {
    return {
      listKey: 0,
      pageTab: "",
      adminDetails: [],
      accountid: "",
      openNote: {
        subject: "",
        message: "",
        date: "",
        from: "",
        new: false,
      },
      folders: "inbox",
      newSubject: "",
      newNotes: 0,
      showNoteList: [],
      account: {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        company: "",
        job: "",
        notes: "",
        notesadmin: "",
        notifications: [],
      },
      table: {
        isLoading: false,
        columns: [
          {
            label: "Order",
            field: "no",
            width: "5%",
            // sortable: true,
            isKey: true,
          },
          {
            label: "Request",
            field: "order",
            width: "30%",
            display: (row) => {
              let template = "";
              for (let i = 0; i < row.products.length; i++) {
                template += `<p>`;

                if (row.products[i].ordertype === "quote") {
                  template += `<span class='badge badge-large badge-quote badge-bottom'>
                     <i class="fa fa-file-text-o"></i></span> `;
                }
                if (row.products[i].ordertype === "order") {
                  template += `<span class='badge badge-large badge-order badge-bottom'>
                     <i class="fa fa-truck"></i></span> `;
                }
                if (row.products[i].ordertype === "sample") {
                  template += `<span class='badge badge-large badge-sample badge-bottom'>
                     <i class="fa fa-gift"></i></span> `;
                }

                template += `
                    ${row.products[i].ordername} - 
                    ${row.products[i].ordercountry}, `;

                if (
                  row.products[i].ordertype === "quote" ||
                  row.products[i].ordertype === "order"
                ) {
                  template += `${row.products[i].ordervariations.bags} kgs `;
                }

                if (row.products[i].ordertype === "sample") {
                  template += `${
                    row.products[i].ordervariations.weight
                  } grams, ${
                    row.products[i].ordervariations.roasted
                      ? "Roasted"
                      : "Green"
                  }`;
                }

                template += `</p>`;
              }
              return `<p>${row.date}</p>` + template;
            },
          },
          {
            label: "Message",
            field: "message",
            width: "30%",
          },
          {
            label: "Status",
            field: "status",
            width: "30%",
          },
          // {
          //   label: "Status",
          //   field: "status",
          //   width: "10%",
          //   display: (row) => {
          //     return `<b class="${row.status}">${row.status}</b>`;
          //   },
          // },
        ],
        rows: [],
        totalRecordCount: 0,
        // sortable: {
        //   order: "no",
        //   sort: "desc",
        // },
      },
    };
  },
  computed: {
    ...mapState(["user", "csrf", "customers", "customer", "admin"]),
    ...mapGetters(["getCustomer"]),
  },
  watch: {
    user() {
      // this.selectFolder(this.folders);
      this.getUpdatedUser();
      // this.$store.dispatch("GETCUSTOMERLIST").then(() => {
      //   this.log("Customer:#06f", "Customers success");
      //   this.account = this.getCustomer(this.accountid);
      // });
    },
  },
  mounted() {
    if (this.$route.params.user) {
      this.accountid = this.$route.params.user;
      setTimeout(() => {
        this.account = this.getCustomer(this.accountid);
        if (!this.account?.accountmanager) {
          this.pageTab = "account";
          this.success("New User", "Please assign a manager.");
        } else {
          this.pageTab = "note";
        }
        this.selectFolder("inbox");
        this.lodeData();
      }, 100);
    } else {
      this.pageTab = "note";
      console.log(this.user);
      this.account = this.user;
      this.selectFolder("inbox");
    }
  },
  methods: {
    showTab(tab) {
      this.pageTab = tab;
    },
    resetFields() {
      this.credentials.password =
        this.credentials.newpassword =
        this.credentials.confirmnewpassword =
          "";
    },
    /*-- --*/
    updateAccountDetails() {
      const postData = {
        account: this.accountid ? this.account : {},
        customer: this.accountid ? true : false,
        new: true,
      };
      Swal.fire({
        title: "Are you sure?",
        text: "All account details will be saved.",
        icon: "warning",
        showDenyButton: true,
        showConfirmButton: true,
        denyButtonText: "No, cancel please",
        confirmButtonText: "Yes, save details",
        confirmButtonColor: "#218838",
      }).then((response) => {
        // console.log(response.isConfirmed);
        // console.log(response.isDenied);
        // console.log(response.isDismissed);
        if (response.isConfirmed) {
          this.$store
            .dispatch("saveUserAPI", postData)
            .then(() => {
              console.log("then");
              this.successSwal();
            })
            .catch((errors) => {
              console.log("Cannot update", errors);
              this.errorSwal();
            });
        } else {
          this.cancelledSwal();
        }
      });
    },
    // Notifications below
    getType(type) {
      switch (type) {
        case "order":
          return "shopping-basket";
        default:
          return "envelope";
      }
    },
    getCol(type) {
      switch (type) {
        case "order":
          return "success";
        default:
          return "info";
      }
    },
    inboxCount() {
      return this.account.notifications?.admininbox?.length || 0;
    },
    sentCount() {
      return this.account.notifications?.adminsent?.length || 0;
    },
    sent() {
      this.newSubject = "";
      this.getUpdatedUser();
    },

    selectFolder(target) {
      this.folders = target;
      console.log("Select folder target = " + target);
      this.showNoteList = [];
      this.openNote = {
        subject: "",
        message: "",
        date: "",
        from: "",
        new: false,
      };
      if (
        target === "inbox" &&
        this.account.notifications?.admininbox?.length >= 1
      ) {
        console.log("Yes inbox");
        this.showNoteList = this.account.notifications.admininbox;
        this.openNote = this.showNoteList[0];
      }
      if (
        target !== "inbox" &&
        this.account.notifications?.adminsent?.length >= 1
      ) {
        console.log("Not inbox");
        this.showNoteList = this.account.notifications.adminsent;
        this.openNote = this.showNoteList[0];
      }
    },
    showNotification(id) {
      this.openNote = this.showNoteList.find((x) => x._id === id);
      console.log(this.openNote);
      this.folders = this.folders === "inbox" ? "inbox" : "sent";
      if (this.folders === "inbox" && this.openNote.new) {
        this.$store
          .dispatch("UPDATEUNREAD", {
            noteId: id,
            list: "admin",
            account: this.accountid,
            folder: this.folders,
          })
          .then((response) => {
            this.log("TipTap:amber", response);
            this.getUpdatedUser();
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    },
    deleteNote(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Your message will be deleted.",
        icon: "warning",
        showDenyButton: true,
        showConfirmButton: true,
        denyButtonText: "No, cancel please",
        confirmButtonText: "Yes, delete message",
        confirmButtonColor: "#218838",
      }).then((response) => {
        if (response.isConfirmed) {
          console.log(id);

          this.$store
            .dispatch("DELETENOTIFICATION", {
              noteId: id,
              list: "admin",
              account: this.accountid,
              folder: this.folders,
            })
            .then((response) => {
              console.log("Delete notification", response);
              this.successSwal("Your message has been deleted.");
              this.openNote = {
                subject: "",
                message: "",
                date: "",
                from: "",
                new: false,
              };
              this.getUpdatedUser();
            })
            .catch((errors) => {
              console.log("Cannot delete", errors);
              this.errorSwal();
            });
        } else {
          this.cancelledSwal("No messages have been removed.");
        }
      });
    },
    getUpdatedUser() {
      this.$store.dispatch("GETCUSTOMERLIST").then(() => {
        this.log("Customer:#06f", "Customers success");
        this.account = this.getCustomer(this.accountid);
        this.selectFolder(this.folders);
      });
    },
    lodeData() {
      const data = this.account.history.map((x) => {
        return {
          no: x.number,
          date: x.date,
          message: x.message,
          products: [...x.order],
          status: { status: x.status, id: x._id },
        };
      });
      console.log(data);
      this.table.rows = data;
      this.table.totalRecordCount = this.table.rows.length;
    },
    onChange(event) {
      console.log(event.target.value);
      const id = event.target.value.split("|")[0];
      const status = event.target.value.split("|")[1];
      this.$store
        .dispatch("UPDATEORDERSTATUS", { status, id, account: this.accountid })
        .then(() => {
          this.log("Customer:#06f", "Order update success");
          this.account = this.getCustomer(this.accountid);
          this.success("Success", "Status updated and customer notified.");

          setTimeout(() => {
            this.lodeData();
          }, 100);
        });
    },
  },
};
</script>
