<template>
  <main class="app-content">
    <div class="app-title">
      <div>
        <h1>History</h1>
        <p>Order, Quote and Sample history.</p>
      </div>
      <ul class="app-breadcrumb breadcrumb">
        <li class="breadcrumb-item">
          <a href="#/user/dashboard"><i class="fa fa-home fa-lg"></i></a>
        </li>
        <li class="breadcrumb-item">
          <a href="#/user/dashboard">Dashboard</a>
        </li>
        <li class="breadcrumb-item">History</li>
      </ul>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="tile">
          <h2 class="mb-3 line-head" id="typography">Recent requests</h2>

          <table-lite
            class="table-lite"
            :is-static-mode="true"
            :columns="table.columns"
            :rows="table.rows"
            :total="table.totalRecordCount"
            :sortable="table.sortable"
          >
          </table-lite>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
// import api from "@/lib/api";
import { mapState } from "vuex";
import VueTableLite from "vue3-table-lite";
// import { defineComponent } from "vue";

export default {
  name: "History",
  components: {
    "table-lite": VueTableLite,
  },
  data() {
    return {
      data: {
        name: "Jay",
      },
      table: {
        isLoading: false,
        columns: [
          {
            label: "Order",
            field: "no",
            width: "5%",
            sortable: true,
            isKey: true,
          },
          {
            label: "Status",
            field: "status",
            width: "10%",
            display: (row) => {
              return `<b class="${row.status}">${row.status}</b>`;
            },
          },
          {
            label: "Request",
            field: "order",
            width: "50%",
            display: (row) => {
              let template = "";
              for (let i = 0; i < row.products.length; i++) {
                template += `<p>`;

                if (row.products[i].ordertype === "quote") {
                  template += `<span class='badge badge-large badge-quote badge-bottom'>
                     <i class="fa fa-file-text-o"></i></span> `;
                }
                if (row.products[i].ordertype === "order") {
                  template += `<span class='badge badge-large badge-order badge-bottom'>
                     <i class="fa fa-truck"></i></span> `;
                }
                if (row.products[i].ordertype === "sample") {
                  template += `<span class='badge badge-large badge-sample badge-bottom'>
                     <i class="fa fa-gift"></i></span> `;
                }

                template += `
                    ${row.products[i].ordername} - 
                    ${row.products[i].ordercountry}, `;

                if (
                  row.products[i].ordertype === "quote" ||
                  row.products[i].ordertype === "order"
                ) {
                  template += `${row.products[i].ordervariations.bags} kgs `;
                }

                if (row.products[i].ordertype === "sample") {
                  template += `${
                    row.products[i].ordervariations.weight
                  } grams, ${
                    row.products[i].ordervariations.roasted
                      ? "Roasted"
                      : "Green"
                  }`;
                }

                template += `</p>`;
              }
              return `<p>${row.date}</p>` + template;
            },
          },
          {
            label: "Message",
            field: "message",
            width: "30%",
          },
        ],
        rows: [],
        totalRecordCount: 0,
        sortable: {
          order: "no",
          sort: "desc",
        },
      },
    };
  },
  computed: {
    ...mapState(["user", "csrf"]),
  },
  mounted() {
    this.log("History", "List", this.user.history);
    setTimeout(() => {
      this.lodeData();
    }, 100);
  },
  methods: {
    lodeData() {
      const data = this.user.history.map((x) => {
        return {
          no: x.number,
          date: x.date,
          message: x.message,
          products: [...x.order],
          status: x.status,
        };
      });
      console.log(data);
      this.table.rows = data;
      this.table.totalRecordCount = this.table.rows.length;
    },
  },
};
</script>
