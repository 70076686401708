<template>
  <main class="app-content">
    <div class="app-title">
      <div>
        <h1>Favourites</h1>
      </div>
      <ul class="app-breadcrumb breadcrumb">
        <li class="breadcrumb-item">
          <a href="#/user/dashboard"><i class="fa fa-home fa-lg"></i></a>
        </li>
        <li class="breadcrumb-item">
          <a href="#/user/dashboard">Dashboard</a>
        </li>
        <li class="breadcrumb-item">Favourites</li>
      </ul>
    </div>
    <div class="app-title product-filter-nav flex-wrap">
      <ul class="product-filter">
        <li>
          <button
            class="btn btn-primary btn-filter"
            @click="openFilter"
            :class="filterOpen ? 'active' : ''"
          >
            <i class="fa fa-filter" aria-hidden="true"></i> Filter
          </button>
        </li>
        <li class="hide-cat">
          <input
            id="staplesBtn"
            ref="staplesBtn"
            @change="updateSearch"
            type="checkbox"
            value="staples"
            v-model="checkedNames"
          />
          <label class="btn btn-primary btn-cat" for="staplesBtn">
            <span class="button-indecator"
              ><img class="leaf" src="@/assets/staples.png" alt="Staples" />
              Staples</span
            >
          </label>
        </li>
        <li class="hide-cat">
          <input
            id="coreBtn"
            ref="coreBtn"
            @change="updateSearch"
            type="checkbox"
            value="core"
            v-model="checkedNames"
          />
          <label class="btn btn-primary btn-cat" for="coreBtn">
            <span class="button-indecator"
              ><img class="leaf" src="@/assets/core.png" alt="Core" />
              Core</span
            >
          </label>
        </li>
        <li class="hide-cat">
          <input
            id="coreplusBtn"
            ref="coreplusBtn"
            @change="updateSearch"
            type="checkbox"
            value="core +"
            v-model="checkedNames"
          />
          <label class="btn btn-primary btn-cat" for="coreplusBtn">
            <span class="button-indecator"
              ><img class="leaf" src="@/assets/coreplus.png" alt="Core +" />
              Core +</span
            >
          </label>
        </li>
        <li class="hide-cat">
          <input
            id="topshelfBtn"
            ref="topshelfBtn"
            @change="updateSearch"
            type="checkbox"
            value="top shelf"
            v-model="checkedNames"
          />
          <label class="btn btn-primary btn-cat" for="topshelfBtn">
            <span class="button-indecator"
              ><img class="leaf" src="@/assets/top.png" alt="Top Shelf" /> Top
              Shelf</span
            >
          </label>
        </li>
        <li v-if="checkedNames.length">
          <button class="btn btn-primary-dark" @click.prevent="resetFilter">
            <i class="fa fa-undo" aria-hidden="true"></i> Reset
          </button>
        </li>
      </ul>

      <ul class="product-view">
        <li>
          <button
            class="btn btn-primary"
            @click="updateView('grid')"
            :class="view === 'grid' ? 'active' : ''"
          >
            <i class="fa fa-th" aria-hidden="true"></i>
          </button>
        </li>
        <li>
          <button
            class="btn btn-primary"
            @click="updateView('list')"
            :class="view === 'list' ? 'active' : ''"
          >
            <i class="fa fa-list" aria-hidden="true"></i>
          </button>
        </li>
      </ul>
      <div class="product-filter-sort">
        <span
          v-for="(btn, index) in sort"
          :key="index"
          :class="`active-${btn.active} sort-${btn.dir}`"
          @click="sortResults(index)"
        >
          {{ btn.name }}
          <i :class="`fa fa-chevron-${btn.dir || 'down'}`"></i>
        </span>
      </div>
    </div>

    <div class="shop" v-if="searchResults.length > 0">
      <div class="tile product-item d-flex flex-column" v-if="searchTerm">
        <h2 class="tile-title search-term">
          Search results for: {{ searchTerm }}
        </h2>
      </div>
      <!-- Grid View -->
      <!-- Start product -->
      <div class="row grid" v-if="view === 'grid'">
        <div
          class="col-6 col-sm-4 col-md-6 col-lg-3 product"
          v-for="(item, index) in displayResults"
          :key="index"
        >
          <div
            :class="`tile product-item d-flex flex-column ${
              item.catagory === 'Core +' ? 'CorePlus' : item.catagory
            }`"
          >
            <a :href="`#/user/shop/${item.slug}`" @click="loadProduct">
              <div
                :class="`catagory ${
                  item.catagory === 'Core +' ? 'CorePlus' : item.catagory
                }`"
              >
                <img :src="`/img/products/${getImage(item.images)}`" alt="" />
                <img
                  class="leaf"
                  :src="`/img/${getPath(item.catagory)}.png`"
                  alt="Core"
                />
              </div>
            </a>
            <div class="details">
              <p>
                <span>Name:</span>
                <a
                  class=""
                  :href="`#/user/shop/${item.slug}`"
                  @click="loadProduct"
                  ><strong>{{ item.name }}</strong></a
                >
              </p>
              <p><span>Country:</span>{{ item.country }}</p>
              <p><span>Variety:</span>{{ item.variety }}</p>
              <p><span>Process:</span>{{ item.process }}</p>
              <p><span>Category:</span>{{ item.catagory }}</p>
              <p><span>Status:</span>{{ item.status || "TBC" }}</p>
            </div>
            <div class="d-flex flex-wrap justify-content-between buttons">
              <div class="fav-heart" @click="likeHeart(item._id)">
                <i
                  :class="`fa ${
                    this.getHeartsAsStrings.includes(item._id.toString())
                      ? 'fa-heart'
                      : 'fa-heart-o'
                  }`"
                ></i>
              </div>
              <button
                class="mb-2 btn btn-outline-sample sample"
                type="button"
                @click="
                  addToCart(
                    item._id,
                    item.name,
                    item.slug,
                    getImage(item.images),
                    item.productcode,
                    item.country,
                    `sample`
                  )
                "
              >
                Add sample
              </button>

              <button
                class="mb-2 btn btn-outline-quote quote"
                type="button"
                @click="
                  addToCart(
                    item._id,
                    item.name,
                    item.slug,
                    getImage(item.images),
                    item.productcode,
                    item.country,
                    `quote`
                  )
                "
              >
                Add quote
              </button>
            </div>

            <button
              class="mb-2 btn btn-outline-dark btn-primary btn-block order"
              type="button"
              @click="
                addToCart(
                  item._id,
                  item.name,
                  item.slug,
                  getImage(item.images),
                  item.productcode,
                  item.country,
                  `order`
                )
              "
            >
              Add Order Enquiry
            </button>

            <a class="" :href="`#/user/shop/${item.slug}`">
              <button class="btn btn-primary-dark btn-block" type="button">
                View product
              </button>
            </a>
          </div>
        </div>
      </div>
      <!-- End product -->

      <!-- List View -->
      <!-- Start product -->
      <div class="row list" v-if="view === 'list'">
        <div
          class="col-sm-12 product-list"
          v-for="(item, index) in displayResults"
          :key="index"
        >
          <div class="tile product-item d-flex flex-row">
            <div
              :class="`catagory ${
                item.catagory === 'Core +' ? 'CorePlus' : item.catagory
              }`"
            >
              <!-- <img src="@/assets/product-image.jpg" alt="" /> -->
              <img
                class="leaf"
                :src="`/img/${getPath(item.catagory)}.png`"
                alt=""
              />
            </div>

            <div class="details">
              <div class="row">
                <div class="col-6 col-sm-4">
                  <p>
                    <span>Name:</span>
                    <a
                      class=""
                      :href="`#/user/shop/${item.slug}`"
                      @click="loadProduct"
                      ><strong>{{ item.name }}</strong></a
                    >
                  </p>
                  <p><span>Country:</span>{{ item.country }}</p>
                </div>
                <div class="col-6 col-sm-4 hide-mobile">
                  <p><span>Variety:</span>{{ item.variety }}</p>
                  <p><span>Process:</span>{{ item.process }}</p>
                </div>
                <div class="col-6 col-sm-4">
                  <p><span>Category:</span>{{ item.catagory }}</p>
                  <p><span>Status:</span>{{ item.status || "TBC" }}</p>
                </div>
              </div>
            </div>
            <div class="buttons">
              <div class="">
                <button
                  class="mb-2 btn btn-outline-sample sample"
                  type="button"
                  @click="
                    addToCart(
                      item._id,
                      item.name,
                      item.slug,
                      getImage(item.images),
                      item.productcode,
                      item.country,
                      `sample`
                    )
                  "
                >
                  Add sample
                </button>
                <button
                  class="mb-2 btn btn-outline-quote quote"
                  type="button"
                  @click="
                    addToCart(
                      item._id,
                      item.name,
                      item.slug,
                      getImage(item.images),
                      item.productcode,
                      item.country,
                      `quote`
                    )
                  "
                >
                  Add quote
                </button>
              </div>

              <button
                class="mb-2 btn btn-outline-dark btn-primary btn-block order"
                type="button"
                @click="
                  addToCart(
                    item._id,
                    item.name,
                    item.slug,
                    getImage(item.images),
                    item.productcode,
                    item.country,
                    `order`
                  )
                "
              >
                Add Order <span>Enquiry</span>
              </button>

              <a class="" :href="`#/user/shop/${item.slug}`">
                <button class="btn btn-primary-dark btn-block" type="button">
                  View <span>product</span>
                </button>
              </a>
              <div class="fav-heart" @click="likeHeart(item._id)">
                <i
                  :class="`fa ${
                    this.getHeartsAsStrings.includes(item._id.toString())
                      ? 'fa-heart'
                      : 'fa-heart-o'
                  } fav`"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End product -->
      <div class="pagination">
        <div class="btn-group mr-2" role="group" aria-label="First group">
          <!-- v-for="index in Math.ceil(searchResults.length / perPage)" displayResults-->
          <!--  -->
          <button
            :disabled="!numPage > 0"
            class="btn btn-secondary"
            @click="updatePageNumber(numPage)"
          >
            &lt;&lt;
          </button>
          <button
            v-for="index in Math.ceil(searchResults.length / perPage)"
            :key="index"
            :class="`btn btn-secondary ${
              index === numPage + 1 ? 'active' : ''
            } ${
              index > numPage + 3 || index < numPage - 1 ? 'hidden' : 'show'
            }`"
            type="button"
            :data-sec="index + ' ' + numPage"
            @click="updatePageNumber(index)"
          >
            {{ index }}
          </button>
          <button
            :disabled="numPage === Math.ceil(searchResults.length / perPage)"
            class="btn btn-secondary"
            @click="updatePageNumber(numPage + 2)"
          >
            &gt;&gt;
          </button>
        </div>
        <div class="btn-group mr-2" role="group" aria-label="Second group">
          <button
            :class="`btn btn-secondary ${4 === perPage ? 'active' : ''}`"
            type="button"
            @click="updatePagination(4)"
          >
            4
          </button>
          <button
            :class="`btn btn-secondary ${10 === perPage ? 'active' : ''}`"
            type="button"
            @click="updatePagination(10)"
          >
            10
          </button>
          <button
            :class="`btn btn-secondary ${20 === perPage ? 'active' : ''}`"
            type="button"
            @click="updatePagination(20)"
          >
            20
          </button>
          <button
            :class="`btn btn-secondary ${50 === perPage ? 'active' : ''}`"
            type="button"
            @click="updatePagination(50)"
          >
            50
          </button>
        </div>
      </div>
    </div>

    <div class="no-shop" v-else>
      <div class="tile product-item d-flex flex-row">
        <h2 class="search-term">No results found: Please search again.</h2>
      </div>
    </div>

    <!-- End Shop -->
    <transition name="slide">
      <div v-if="filterOpen" class="app-content filter-draw">
        <div class="app-title product-filter-nav flex-wrap">
          <button class="btn btn-primary" @click.prevent="openFilter">
            <i class="fa fa-chevron-left" aria-hidden="true"></i> Update
          </button>
          <div class="product-filter product-filter-title">
            <i class="fa fa-filter" aria-hidden="true"></i> Filters
          </div>
          <div class="product-view">
            <button class="btn btn-primary" @click.prevent="resetFilter">
              <i class="fa fa-undo" aria-hidden="true"></i> Reset
            </button>
          </div>
        </div>
        <div class="filter-scroll">
          <div class="row">
            <div class="col-md-6 col-lg-3">
              <h3>Countries</h3>
              <ul>
                <li v-for="(country, index) in countries" :key="index">
                  <div class="toggle lg">
                    <label>
                      <input
                        @change="updateSearch"
                        type="checkbox"
                        :value="country.country.toLowerCase()"
                        v-model="checkedNames"
                      /><span class="button-indecator">{{
                        country.country
                      }}</span>
                    </label>
                  </div>
                </li>
              </ul>
            </div>

            <div class="col-md-6 col-lg-9">
              <div class="row">
                <div class="col-lg-4">
                  <h3>Category</h3>
                  <ul>
                    <li
                      v-for="(cat, index) in [
                        'Staples',
                        'Core',
                        'Core +',
                        'Top Shelf',
                      ]"
                      :key="index"
                    >
                      <div class="toggle lg">
                        <label>
                          <input
                            @change="updateSearch"
                            type="checkbox"
                            :value="cat.toLowerCase()"
                            v-model="checkedNames"
                          /><span class="button-indecator">{{ cat }}</span>
                        </label>
                      </div>
                    </li>
                  </ul>
                  <p>&nbsp;</p>
                </div>

                <div class="col-lg-4">
                  <h3>Process</h3>
                  <ul>
                    <li v-for="(process, index) in getProcesses" :key="index">
                      <div class="toggle lg">
                        <label>
                          <input
                            @change="updateSearch"
                            type="checkbox"
                            :value="process.toLowerCase()"
                            v-model="checkedNames"
                          />
                          <span class="button-indecator">{{ process }}</span>
                        </label>
                      </div>
                    </li>
                  </ul>
                  <p>&nbsp;</p>
                </div>

                <div class="col-lg-4">
                  <h3>Status</h3>
                  <ul>
                    <li
                      v-for="(state, index) in [
                        'Landing Soon',
                        'Fresh Arrival',
                        'New Arrival',
                        'Last Chance',
                        'Prev. Crop',
                        'Available',
                        'Discounted',
                      ]"
                      :key="index"
                    >
                      <div class="toggle lg">
                        <label>
                          <input
                            @change="updateSearch"
                            type="checkbox"
                            :value="state"
                            v-model="checkedNames"
                          /><span class="button-indecator">{{ state }}</span>
                        </label>
                      </div>
                    </li>
                  </ul>
                  <p>&nbsp;</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- <Product :user="user" /> -->
  </main>
</template>
<script>
import { mapState, mapGetters } from "vuex";
// import Product from "@/views/Product.vue";
import { gsap, Draggable } from "gsap/all";
gsap.registerPlugin(Draggable);
export default {
  name: "Favourites",
  //   components: { Product },
  props: {
    // user: {
    //   type: Object,
    // },
  },
  data() {
    return {
      //   showhearts: [],
      theColor: "green",
      filterOpen: false,
      w: window.innerHeight,
      checkedNames: [],
      sort: [
        { name: "Name", active: false, dir: "" },
        { name: "Category", active: false, dir: "" },
        { name: "Country", active: false, dir: "" },
        { name: "Process", active: false, dir: "" },
        { name: "Status", active: false, dir: "" },
      ],
      numPage: 0,
      perPage: 10,
      startNum: 0,
      endNum: 10,
      displayResults: [],
    };
  },
  computed: {
    ...mapState([
      "searchResults",
      "searchTerm",
      "searchFilter",
      "hearts",
      "user",
      "csrf",
      "countries",
      "view",
    ]),
    ...mapGetters(["getHeartsAsStrings", "getProcesses"]),
  },
  watch: {
    searchResults: function () {
      this.updatePagination(this.perPage);
    },
  },
  mounted() {
    this.log("Favourites:#06f", "Mounted", this.$route.params.content);

    // this.$store.dispatch("GETHEARTSINDB").then(() => {
    //   this.log("Favourites:#f60", "Fav heart list updated");
    //   this.$store.dispatch("getProductsfilter", {
    //     term: "",
    //     page: "favourites", //this.$route.params.content,
    //   });
    // });

    this.$store.dispatch("getProductsfilter", {
      term: "",
      page: "favourites", //this.$route.params.content,
    });
    this.checkedNames = this.searchFilter;
    this.updatePagination(this.perPage);
    if (this.searchResults.length < 1) {
      setTimeout(() => {
        // this.log("Favourites:#06f", "searchResults", this.searchResults);
        this.updatePagination(this.perPage);
      }, 300);
    }
  },
  methods: {
    showProduct(slug) {
      this.$store.dispatch("getProduct", slug).then((result) => {
        console.log(result);
        this.$router.push({ path: `/user/shop/${slug}` });
      });
    },
    updatePageNumber(select) {
      this.numPage = select - 1;

      this.startNum = this.numPage * this.perPage;
      this.endNum = this.startNum + this.perPage;

      this.displayResults = this.searchResults.slice(
        this.startNum,
        this.endNum
      );
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    updatePagination(count) {
      this.perPage = count;
      this.numPage = 0;

      this.startNum = 0;
      this.endNum = this.startNum + this.perPage;

      this.displayResults = this.searchResults.slice(
        this.startNum,
        this.endNum
      );
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    getPath(cat) {
      if (cat === "Top Shelf") return "top";
      if (cat === "Core +") return "coreplus";
      return cat.toLowerCase();
    },
    getImage(list) {
      return list.split(",")[0];
    },
    sortResults(order) {
      this.sort.forEach((x) => {
        x.active = false;
        // x.dir = "down";
      });
      this.sort[order].active = true;
      this.sort[order].dir = this.sort[order].dir === "down" ? "up" : "down";
      this.$store
        .dispatch("sortResults", {
          sorter: this.sort[order].name.toLowerCase(),
          dir: this.sort[order].dir,
        })
        .then((result) => {
          if (result) this.updatePagination(this.perPage);
        });
    },
    updateView(select) {
      this.$store.commit("changeShopView", select);
      this.updateSearch();
    },
    resetFilter() {
      this.checkedNames = [];
      this.updateSearch();
    },
    updateSearch() {
      this.$store.commit("saveFilterInStore", this.checkedNames);
      this.$store
        .dispatch("getProductsfilter", {
          term: this.searchTerm || "",
          page: this.$route.params.content,
        })
        .then((result) => {
          if (result) this.updatePagination(this.perPage);
        });
    },
    openFilter() {
      document.querySelector("body").classList.toggle("scrolllocked");
      this.filterOpen = this.filterOpen === true ? false : true;
      // const callback = (Xpercent) => {
      //   // console.log(Xpercent);
      //   gsap.to(".filter-draw", {
      //     duration: 0.2,
      //     x: Xpercent > 50 ? "100%" : "0%",
      //     onComplete: () => {
      //       this.filterOpen = Xpercent > 50 ? false : true;
      //       if (Xpercent > 50)
      //         document.querySelector("body").classList.remove("scrolllocked");
      //     },
      //   });
      // };

      // setTimeout(() => {
      //   Draggable.create(".filter-draw", {
      //     edgeResistance: 1,
      //     type: "x",
      //     bounds: { minX: 0, maxX: window.innerWidth },
      //     onRelease: function () {
      //       this.enable();
      //       // console.log("Drag enable");
      //     },
      //     onDragStart: function () {
      //       // console.log("Drag start");
      //       this.enable();
      //     },
      //     // onDrag: function () {
      //     // console.log(this.x, this.y, this.rotation);
      //     // Xpercent = Math.round((this.x / window.innerWidth) * 100);
      //     // console.log(Xpercent);
      //     // },
      //     onDragEnd: function () {
      //       // console.log("Drag end");
      //       let Xpercent = Math.round((this.x / window.innerWidth) * 100);
      //       // console.log(Xpercent);
      //       callback(Xpercent);
      //     },
      //   });
      // }, 501);
    },

    likeHeart(id) {
      this.log("Favourites:#f60", "Liked", id);
      this.$store.dispatch("UPDATEHEARTSINDB", id).then(() => {
        this.log("Favourites:#f60", "Id dispatched from Shop.");
        this.$store.dispatch("GETHEARTSINDB").then(() => {
          this.log("Favourites:#f60", "Fav heart list updated");
        });
      });
    },
    addToCart(
      prodId,
      prodName,
      prodSlug,
      prodImage,
      prodCode,
      prodCountry,
      prodType
    ) {
      // TODO: Seperate Add and Remove
      this.log("Favourites:#f60", "Add to cart", {
        prodId,
        prodName,
        prodSlug,
        prodCode,
        prodImage,
        prodCountry,
        prodType,
      });
      this.$store
        .dispatch("UPDATECARTINDB", {
          prodId,
          prodName,
          prodSlug,
          prodImage,
          prodCode,
          prodCountry,
          prodType,
          prodVariations: {
            weight: 50,
            roasted: true,
            bags: 5,
          },
        })
        .then((data) => {
          this.log("Favourites:#f60", "Cart added");
          // this.$store.dispatch("GETHEARTSINDB").then(() => {
          //   console.log("Fav heart list updated");
          // });
          let type = "Item";
          console.log("THIS", data.prodType);
          if (data.prodType === "sample") type = "Sample";
          if (data.prodType === "quote") type = "Quote";
          if (data.prodType === "order") type = "Order enquiry";

          this.success("Success", `${type} added to cart`);
        });
    },
  },
};
</script>
<style></style>
