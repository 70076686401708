<template>
  <div v-if="loggedin">
    <Header @logOutClicked="logOut" />
    <!-- @updateContent="getContent" -->
    <Sidebar @logOutClicked="logOut" :user="user" :content="content" />
    <Dashboard v-if="content === `dashboard`" :user="user" />
    <Cart v-if="content === `cart`" :user="user" />
    <Account v-if="content === `account`" :user="user" />
    <Customer v-if="content === `customer`" :user="user" />
    <Notifications v-if="content === `notifications`" :user="user" />
    <Shop v-if="content === `shop`" :user="user" />
    <History v-if="content === `history`" :user="user" />
    <Favourites v-if="content === `favourites`" :user="user" />
    <NotFound v-if="content === `404`" :display="'app-content'" />
    <Editor v-if="content === `editor` && product !== ``" />
    <Product v-if="content === `` && product !== ``" />
    <Customers v-if="content === `customers`" :user="user" />
    <Admins v-if="content === `admins`" :user="user" />
  </div>
</template>
<script>
import router from "../router";
import api from "@/lib/api";
import { mapState } from "vuex";
import Header from "@/components/Header.vue";
import Sidebar from "@/components/Sidebar.vue";
import Dashboard from "@/views/Dashboard.vue";
import Customers from "@/views/Customers.vue";
import Admins from "@/views/Admins.vue";
import Customer from "@/views/Customer.vue";
import Cart from "@/views/Cart.vue";
import NotFound from "@/views/404.vue";
import Account from "@/views/Account.vue";
import Notifications from "@/views/Notifications.vue";
import Shop from "@/views/Shop.vue";
import Favourites from "@/views/Favourites.vue";
import Product from "@/views/Product.vue";
import History from "@/views/History.vue";
import Editor from "@/views/Editor.vue";
export default {
  name: "Login",
  components: {
    Header,
    Sidebar,
    Dashboard,
    Cart,
    NotFound,
    Account,
    Notifications,
    Shop,
    Product,
    Editor,
    Favourites,
    History,
    Customers,
    Customer,
    Admins,
  },
  data() {
    return {
      loggedin: false,
      content: "Dashboard",
      internalRouts: [
        "dashboard",
        "cart",
        "account",
        "notifications",
        "shop",
        "favourites",
        "history",
        "customers",
        "admins",
        "customer",
        "editor",
      ],
      product: "",
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    ...mapState(["user", "csrf"]),
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
      this.windowResize();
    });
    this.content = this.internalRouts.find(
      (x) => x === this.$route.params.content
    )
      ? this.$route.params.content
      : "404";
    document.body.classList.add("app", "user", "sidebar-mini");
    this.isLoggedIn();

    // console.log("CONTENT", this.$route.params.content);
    // console.log("USER", this.$route.params.user);
  },
  updated() {
    if (this.$route.params.user) {
      // this.customer = this.$route.params.user;
      // console.log(this.product);
      this.content = "customer";
    } else if (this.$route.params.product) {
      this.product = this.$route.params.product;
      // console.log("PRODUCT", this.product);
      this.content = this.$route.params.edit ? "editor" : "";
    } else {
      this.content = this.internalRouts.find(
        (x) => x === this.$route.params.content
      )
        ? this.$route.params.content
        : "404";
    }
  },
  methods: {
    windowResize() {
      if (this.windowWidth < 500) {
        document.body.classList.add("mob-500");
      } else {
        document.body.classList.remove("mob-500");
      }
    },
    isLoggedIn() {
      api
        .get("/api/loggedin")
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.loggedin = true;
            this.getUserData();
          }
        })
        .catch((errors) => {
          console.log(errors);
          router.push("/login");
        });
    },
    getUserData() {
      // console.log(this.$store?.user);
      // if (this.$store?.user) {
      this.$store.dispatch("getUserAPI").then(() => {
        console.log("User saved to store.");
        this.getProductData();
        if (this.user.admin > 0) this.getcustomerList();
      });
      // }
    },
    getcustomerList() {
      this.$store.dispatch("GETCUSTOMERLIST").then(() => {
        this.log("Customer:#06f", "Customers success");
      });
    },
    getProductData() {
      // console.log(this.$store?.user);
      // if (this.$store?.user) {
      this.$store.dispatch("GETSHOPPRODUCTS").then(() => {
        console.log("Products saved to store.");
        this.getheartData();
        // console.log(this.searchResults);
      });
      // }
    },
    getheartData() {
      this.$store.dispatch("GETHEARTSINDB").then(() => {
        console.log("Fav heart list updated");
      });
    },
    logOut() {
      api
        .get("/api/logout")
        .then((response) => {
          console.log(response);
          this.$store.commit("resetUserInStore");
          router.push("/login");
          this.success("Success", "You have been logged out.");
        })
        .catch((error) => {
          console.log(error);
          router.push("/login");
          this.error("Warning", "Logout error, try again.");
        });
    },
  },
};
</script>
