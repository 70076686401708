<template>
  <main class="app-content">
    <div class="app-title">
      <div>
        <h1>Cart</h1>
        <p>Submit Order, Quote and Sample request.</p>
      </div>
      <ul class="app-breadcrumb breadcrumb">
        <li class="breadcrumb-item">
          <a href="#/user/dashboard"><i class="fa fa-home fa-lg"></i></a>
        </li>
        <li class="breadcrumb-item">
          <a href="#/user/dashboard">Dashboard</a>
        </li>
        <li class="breadcrumb-item">Cart</li>
      </ul>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="tile full favourites cart">
          <h3 class="tile-title">Selection</h3>
          <div class="d-flex flex-column">
            <table class="table">
              <tbody v-if="user.hasOwnProperty('cart') && user.cart.length">
                <!-- Start favs -->
                <tr v-for="(item, index) in user.cart" :key="index">
                  <td class="position-relative">
                    <div class="cart-remove" @click="removeFromCart(index)">
                      <i
                        class="fa fa-times-circle text-secondary"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </td>
                  <td class="position-relative">
                    <img
                      class="img"
                      :src="`/img/products/${item.orderimage}`"
                      alt=""
                    />
                    <!-- <div
                      class="fav-heart item-heart"
                      @click="likeHeart(item._id)"
                    >
                      <i
                        :class="`fa ${
                          this.getHeartsAsStrings.includes(item._id.toString())
                            ? 'fa-heart'
                            : 'fa-heart-o'
                        }`"
                      ></i>
                    </div> -->
                  </td>
                  <td class="position-relative">
                    <a :href="`#/user/shop/${item.orderslug}`">
                      <h5 class="name">{{ item.ordername }}</h5>
                      -
                      <h5 class="city">{{ item.ordercountry }}</h5>
                    </a>

                    <!-- <a href="/countries/brazil" @click.prevent="testErrors"> -->

                    <!-- </a> -->

                    <div v-if="item.ordertype === 'sample'">
                      <span class="badge badge-large badge-sample badge-bottom">
                        <i class="fa fa-gift" aria-hidden="true"></i> Sample
                      </span>
                      <div class="input-group">
                        <div class="input-group-prepend pre-label">
                          <span class="input-group-text">Weight</span>
                        </div>
                        <input
                          :ref="`weight${index}`"
                          class="form-control"
                          type="text"
                          placeholder="Sample weight"
                          @change="updateCart('weight', index)"
                          :value="`${item.ordervariations.weight}`"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text">Grams</span>
                        </div>
                      </div>
                      <div class="animated-radio-button">
                        <label>
                          <input
                            :ref="`roasted${index}`"
                            type="radio"
                            :name="`${item.ordercode}-coffee`"
                            value="Roasted"
                            @change="updateCart('roasted', index, true)"
                            :checked="item.ordervariations.roasted === 'true'"
                          />
                          <span class="label-text">Roasted</span>
                        </label>
                      </div>
                      <div class="animated-radio-button">
                        <label>
                          <input
                            :ref="`roasted${index}`"
                            type="radio"
                            :name="`${item.ordercode}-coffee`"
                            value="Green"
                            @change="updateCart('roasted', index, false)"
                            :checked="item.ordervariations.roasted === 'false'"
                          />
                          <span class="label-text">Green</span>
                        </label>
                      </div>
                    </div>

                    <div v-if="item.ordertype === 'quote'">
                      <span class="badge badge-large badge-quote badge-bottom">
                        <i class="fa fa-file-text-o" aria-hidden="true"></i>
                        Quote
                      </span>
                      <div class="input-group">
                        <div class="input-group-prepend pre-label">
                          <span class="input-group-text">Quote</span>
                        </div>
                        <input
                          :ref="`bags${index}`"
                          class="form-control"
                          type="text"
                          placeholder="Number of kgs"
                          @change="updateCart('bags', index)"
                          :value="`${item.ordervariations.bags}`"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text">kgs</span>
                        </div>
                      </div>
                    </div>

                    <div v-if="item.ordertype === 'order'">
                      <span class="badge badge-large badge-order badge-bottom">
                        <i class="fa fa-truck" aria-hidden="true"></i> Order
                      </span>
                      <div class="input-group">
                        <div class="input-group-prepend pre-label">
                          <span class="input-group-text"> Order</span>
                        </div>
                        <input
                          :ref="`bags${index}`"
                          class="form-control"
                          type="text"
                          placeholder="Number of kgs"
                          @change="updateCart('bags', index)"
                          :value="`${item.ordervariations.bags}`"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text">kgs</span>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td>
                    <p class="text-center no-samples">
                      You have no requested samples.
                    </p>
                  </td>
                </tr>
                <!-- End favs -->
              </tbody>
            </table>
            <button
              v-if="user.hasOwnProperty('cart') && !user.cart.length"
              class="btn btn-primary-dark btn-block"
              type="button"
            >
              See order history
            </button>
            <button
              v-if="user.hasOwnProperty('cart') && user.cart.length"
              class="btn btn-primary-dark btn-block"
              type="button"
              :disabled="ready"
              @click.prevent="success('Success', 'Cart updated')"
            >
              Update cart
            </button>
          </div>
          <div class="overlay overlay--50" v-if="sending">
            <div class="m-loader m-loader--80">
              <svg class="m-circular" viewBox="25 25 50 50">
                <circle
                  class="path"
                  cx="50"
                  cy="50"
                  r="20"
                  fill="none"
                  stroke-width="4"
                  stroke-miterlimit="10"
                ></circle>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="tile full" v-if="completed">
          <h3 class="tile-title">Order confirmation</h3>
          <div
            class="d-flex flex-column justify-content-center"
            style="height: 70%"
          >
            <h3>Thank you for your order</h3>
            <p>Your representative will be in touch soon.</p>
          </div>
        </div>
        <div class="tile full order-details" v-else>
          <h3 class="tile-title">Order details</h3>
          <div class="d-flex flex-column">
            <table class="table">
              <tbody>
                <tr>
                  <td>Order:</td>
                  <td>
                    <strong>{{
                      user.ordernumber
                        ? ("00000" + (user.ordernumber + 1)).slice(-5)
                        : "00001"
                    }}</strong>
                  </td>
                </tr>
                <tr>
                  <td>Company:</td>
                  <td>{{ user.company }}</td>
                </tr>
                <tr>
                  <td>Name:</td>
                  <td>{{ user.firstname }} {{ user.lastname }}</td>
                </tr>
                <tr>
                  <td>Phone:</td>
                  <td>{{ user.phone }}</td>
                </tr>
                <tr>
                  <td>Email:</td>
                  <td>{{ user.email }}</td>
                </tr>
                <tr>
                  <td colspan="2">
                    <div class="cart-message">
                      <p>Message:</p>
                      <textarea
                        v-model="cartMessage"
                        class="form-control"
                        id="InputNotes"
                        type="notes"
                        rows="5"
                        required=""
                        name="notes"
                        aria-describedby=""
                        placeholder="Additional requests"
                      ></textarea>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- <div v-if="user.hasOwnProperty('cart') && user.cart.length">
              <h3 class="tile-title">Product details</h3>
              <table class="table">
                <tbody> -->
            <!-- <tr
                    v-if="
                      user.hasOwnProperty('cart') &&
                      user.cart.find((x) => x.ordertype === 'sample')
                    "
                  >
                    <td colspan="2">
                      <p>
                        Please select the sample type<span class="required"
                          >*</span
                        >
                      </p>
                      <div class="animated-radio-button">
                        <label>
                          <input
                            type="radio"
                            name="coffee"
                            v-model="cartRoast"
                            value="Roasted"
                            @change="readyToSend"
                          />
                          <span class="label-text">Roasted</span>
                        </label>
                      </div>
                      <div class="animated-radio-button">
                        <label>
                          <input
                            type="radio"
                            name="coffee"
                            v-model="cartRoast"
                            value="Green"
                            @change="readyToSend"
                          />
                          <span class="label-text">Green</span>
                        </label>
                      </div>
                    </td>
                  </tr> -->
            <!-- <tr
                    v-if="
                      user.hasOwnProperty('cart') &&
                      user.cart.find((x) => x.ordertype === 'sample')
                    "
                  >
                    <td colspan="2">
                      <p>
                        Please select the sample weight (up to 200g)<span
                          class="required"
                          >*</span
                        >
                      </p>

                      <select
                        class="form-control"
                        id="sampleweight"
                        v-model="cartWeight"
                        @change="readyToSend"
                      >
                        <option v-if="!cartWeight">Select</option>
                        <option value="50g">50g</option>
                        <option value="100g">100g</option>
                        <option value="150g">150g</option>
                        <option value="200g">200g</option>
                      </select>
                    </td>
                  </tr> -->
            <!-- <tr
                    v-if="
                      user.hasOwnProperty('cart') &&
                      user.cart.find((x) => x.ordertype === 'quote')
                    "
                  >
                    <td colspan="2">
                      <p>
                        Please select the quote quantity<span class="required"
                          >*</span
                        >
                      </p>

                      <select
                        class="form-control"
                        id="quotequantity"
                        v-model="cartQuant"
                        @change="readyToSend"
                      >
                        <option v-if="!cartQuant">Select</option>
                        <option value="< 5 Bag(s)">&LT; 5 Bag(s)</option>
                        <option value="6 to 12 Bag(s)">6 to 12 Bag(s)</option>
                        <option value="> 16 Bag(s)">&GT; 16 Bag(s)</option>
                      </select>
                    </td>
                  </tr> -->
            <!-- </tbody>
              </table>
            </div> -->
            <button
              class="btn btn-success btn-block"
              type="button"
              v-on:click="sendCartOrder()"
              :disabled="ready"
            >
              Submit request
            </button>
          </div>
          <div class="overlay overlay--50" v-if="sending">
            <div class="m-loader m-loader--80">
              <svg class="m-circular" viewBox="25 25 50 50">
                <circle
                  class="path"
                  cx="50"
                  cy="50"
                  r="20"
                  fill="none"
                  stroke-width="4"
                  stroke-miterlimit="10"
                ></circle>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2";
export default {
  name: "Cart",
  components: {},
  data() {
    return {
      htmlmessage: "",
      heartListDisplay: [],
      file: "",
      completed: false,
      cartMessage: "",
      cartRoast: "",
      cartWeight: "",
      cartQuant: "",
      sending: false,
      ready: false,
      update: false,
    };
  },
  computed: {
    ...mapState(["products", "user", "csrf", "heartsObjects"]),
    ...mapGetters(["getHartsAsObject"]),
  },
  watch: {},
  mounted() {},
  methods: {
    getImage(list) {
      return list.split(",")[0];
    },
    readyToSend() {
      this.ready = true;
      if (!this.user.cart.length) {
        this.ready = false;
      }
      if (this.user.cart.find((x) => x.ordertype === "quote")) {
        if (!this.cartQuant) {
          this.ready = false;
        }
      }
      if (this.user.cart.find((x) => x.ordertype === "sample")) {
        if (!this.cartWeight || !this.cartRoast) {
          this.ready = false;
        }
      }
    },
    updateCart(property, index, check = null) {
      const value = this.$refs[property + index][0].value;
      this.log("Cart:#0f6", "update", { index, property, value });
      const update = {
        index,
        property,
        value: property === "roasted" ? check : value,
      };
      this.$store.dispatch("UPDATECARTINORDER", update).then(() => {
        this.log("Cart:#0f6", "order updated");
      });
    },
    removeFromCart(index) {
      this.log("Cart:#0f6", "Remove from cart", this.user.cart[index]);
      this.$store.dispatch("REMOVEFROMCARTINDB", index).then(() => {
        this.log("Cart:#0f6", "Cart removed");
        this.success("Success", `Item removed from cart`);
      });
    },
    addToCart(
      prodId,
      prodName,
      prodSlug,
      prodImage,
      prodCode,
      prodCountry,
      prodType,
      prodRoast,
      prodWeight,
      prodQuant
    ) {
      this.log("Shop:#06f", "Add to cart");
      this.$store
        .dispatch("UPDATECARTINDB", {
          prodId,
          prodName,
          prodImage,
          prodSlug,
          prodCode,
          prodCountry,
          prodType,
          prodRoast,
          prodWeight,
          prodQuant,
        })
        .then((data) => {
          this.log("Shop:#06f", "Cart added");
          // this.$store.dispatch("GETHEARTSINDB").then(() => {
          //   console.log("Fav heart list updated");
          // });
          let type = "Item";
          console.log("THIS", data.prodType);
          if (data.prodType === "sample") type = "Sample";
          if (data.prodType === "quote") type = "Quote";
          if (data.prodType === "order") type = "Order enquiry";

          this.success("Success", `${type} added to cart`);
        });
    },
    sendCartOrder() {
      Swal.fire({
        title: "Your request will be submitted?",
        text: "You will not be charged for these requests.",
        icon: "warning",
        showDenyButton: true,
        showConfirmButton: true,
        denyButtonText: "No, cancel please",
        confirmButtonText: "Yes, send order",
        confirmButtonColor: "#218838",
      }).then((response) => {
        if (response.isConfirmed) {
          this.sending = true;
          this.successSwal("Submitting your order");
          this.$store
            .dispatch("SENDCARTORDER", {
              message: this.cartMessage,
              roast: this.cartRoast,
              weight: this.cartWeight,
              quant: this.cartQuant,
              csrf: this.csrf,
            })
            .then((response) => {
              this.log("Cart:pink", response);
              this.sending = false;
              this.completed = true;
              this.success("Success", `Your request has been submitted.`);
            });
        } else {
          this.cancelledSwal("Order canceled");
        }
      });
    },
    likeHeart(id) {
      this.log("Favourites:#f60", "Liked", id);
      this.$store.dispatch("UPDATEHEARTSINDB", id).then(() => {
        this.log("Favourites:#f60", "Id dispatched from Shop.");
        this.$store.dispatch("GETHEARTSINDB").then(() => {
          this.log("Favourites:#f60", "Fav heart list updated");
        });
      });
    },
  },
};
</script>
