<template>
  <header class="app-header">
    <a class="app-header__logo" href="/#/user/dashboard">
      <img src="@/assets/upstream-logo-inline.svg" alt="Upstream" />
    </a>

    <!--if user.admin-->
    <a
      class="app-sidebar__toggle"
      href="#"
      data-toggle="sidebar"
      aria-label="Hide Sidebar"
      @click.prevent="toggleSidebar"
    >
    </a>
    <ul class="app-nav">
      <li class="app-search">
        <input
          class="app-search__input"
          type="search"
          ref="searchField"
          placeholder="Search"
          v-model="searchTerm"
          v-on:keyup="searchProducts"
          v-on:mouseup="searchProducts(event)"
          @search="searchProducts"
        /><button @click="searchProducts" class="app-search__button">
          <i class="fa fa-search"></i>
        </button>
        <ul
          class="app-search__suggest"
          v-if="
            searchList.length && searchTerm !== searchListItem && searchActive
          "
        >
          <li
            v-for="(item, index) in searchList"
            :key="index"
            @click.prevent="searchQuck(item)"
            @mouseover="searchOver(index, item)"
            :class="`active-${index === searchListSelect}`"
          >
            {{ item.toLowerCase() }}
          </li>
        </ul>
      </li>
      <li class="app-search-toggle">
        <a
          @click.prevent="toggleSearch()"
          class="app-nav__item show-settings"
          href="#"
          data-toggle="dropdown"
          aria-label="Open Profile Menu"
          ><i class="fa fa-search"></i
        ></a>
      </li>
      <li>
        <a class="app-nav__item show-cart" href="#/user/cart">
          <i class="fa fa-shopping-basket"></i>
          <span
            v-if="user.cart && user.cart.length > 0"
            class="badge badge-pill badge-danger"
            >{{ user.cart.length }}</span
          >
        </a>
      </li>
      <li class="dropdown" @mouseleave="openNotifications = false">
        <a
          @click.prevent="toggleNotifications()"
          class="app-nav__item show-notifications"
          href="#"
          data-toggle="dropdown"
          aria-label="Show notifications"
          ><i class="fa fa-bell-o fa-lg"></i>
          <span v-if="newNotes" class="badge badge-pill badge-danger">{{
            newNotes
          }}</span></a
        >
        <ul
          class="app-notification dropdown-menu dropdown-menu-right"
          v-if="openNotifications"
          tabindex="-1"
        >
          <li class="app-notification__title">
            You have {{ newNotes }} new notification<span v-if="newNotes > 1"
              >s</span
            >.
          </li>
          <div v-if="user.admin === 0" class="app-notification__content">
            <!-- -->
            <li
              v-for="(note, index) in user.notifications?.userinbox"
              :key="index"
            >
              <a class="app-notification__item" href="#/user/notifications"
                ><span class="app-notification__icon"
                  ><span class="fa-stack fa-lg"
                    ><i class="fa fa-circle fa-stack-2x text-info"></i
                    ><i class="fa fa-envelope fa-stack-1x fa-inverse"></i></span
                ></span>
                <div>
                  <p class="app-notification__message">{{ note.subject }}</p>
                  <p class="app-notification__meta">{{ note.date }}</p>
                </div></a
              >
            </li>
            <!-- -->
          </div>
          <li class="app-notification__footer">
            <a v-if="user.admin === 0" href="#/user/notifications"
              >See all notifications.</a
            >
            <a v-else href="#/user/customers">See all notifications.</a>
          </li>
        </ul>
      </li>
      <li class="dropdown" @mouseleave="openSettings = false">
        <a
          @click.prevent="toggleSettings()"
          class="app-nav__item show-settings"
          href="#"
          data-toggle="dropdown"
          aria-label="Open Profile Menu"
          ><i class="fa fa-user fa-lg"></i
        ></a>
        <ul
          class="dropdown-menu settings-menu dropdown-menu-right"
          v-if="openSettings"
          tabindex="-1"
        >
          <li>
            <a class="dropdown-item" href="#/user/account"
              ><i class="fa fa-user fa-lg"></i> Account details</a
            >
          </li>
          <li>
            <a class="dropdown-item" href="#" @click.prevent="logOutClick"
              ><i class="fa fa-sign-out fa-lg"></i> Logout</a
            >
          </li>
        </ul>
      </li>
    </ul>
    <div
      class="app-search app-search-mob"
      v-if="mobSearch"
      tabindex="-1"
      @focusout="if (!searchList.length) mobSearch = false;"
    >
      <input
        class="app-search__input"
        type="search"
        placeholder="Search"
        v-model="searchTerm"
        v-on:keyup="searchProducts"
        v-on:mouseup="searchProducts"
        @search="searchProducts"
      /><button @click="searchProducts" class="app-search__button">
        <i class="fa fa-search"></i>
      </button>
      <ul
        class="app-search__suggest"
        v-if="searchList.length && searchTerm !== searchListItem"
      >
        <li
          v-for="(item, index) in searchList.slice(0, 10)"
          :key="index"
          @click.prevent="searchQuck(item)"
          :class="`active-${index === searchListSelect}`"
        >
          {{ item.toLowerCase() }}
        </li>
      </ul>
    </div>
  </header>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "Content",
  components: {},
  data() {
    return {
      mobSearch: false,
      openNotifications: false,
      openSettings: false,
      newNotes: 0,
      searchTerm: "",
      searchList: [],
      searchListSelect: 10,
      searchListItem: "",
      searchActive: false,
    };
  },
  computed: {
    ...mapState(["user", "csrf"]),
    ...mapActions(["getProductsfilter"]),
  },
  watch: {
    user() {
      this.newNotes = this.user.unread;
    },
    "$route.params.content": function () {
      this.searchTerm = "";
      this.searchListItem = "";
      console.log("Router changed");
    },
  },
  mounted() {
    // console.log(this.user); // Wont work, too early.
    // document.querySelector(".app-content").addEventListener("click", (e) => {
    //   console.log(e.target);
    //   this.mobSearch = false;
    //   this.openNotifications = false;
    //   this.openSettings = false;
    // });
  },
  methods: {
    checkFocus() {
      console.log("This focus ", document.activeElement.tagName === "INPUT");
      return document.activeElement.tagName === "INPUT";
    },
    searchQuck(item) {
      this.searchTerm = item;
      this.searchListItem = item;
      this.mobSearch = false;
      this.callSearch();
    },
    searchOver(index, item) {
      this.searchListSelect = index;
      this.searchListItem = item;
    },
    searchProducts(e = "") {
      this.searchActive = true;

      console.log("List = " + this.searchListItem, "Term = " + this.searchTerm);
      if (this.searchTerm.length < 2) this.searchListItem = "";

      if (e && e.keyCode === 13) {
        this.searchTerm = this.searchListItem || this.searchTerm;
        this.searchQuck(this.searchTerm);
        this.searchActive = false;
      } else if (e && e.keyCode === 38) {
        this.searchListSelect =
          this.searchListSelect < 1
            ? this.searchList.length - 1
            : (this.searchListSelect -= 1);
        this.searchListItem = this.searchList[this.searchListSelect];
      } else if (e && e.keyCode === 40) {
        this.searchListSelect =
          this.searchListSelect > this.searchList.length - 1
            ? 0
            : (this.searchListSelect += 1);
        this.searchListItem = this.searchList[this.searchListSelect];
      } else {
        this.searchListSelect = 10;
        this.callSearch();
      }
    },
    callSearch() {
      // console.log("Search activated");
      this.$store
        .dispatch("getProductsfilter", {
          term: this.searchTerm || "",
          page: this.$route.params.content,
        })
        .then((list) => {
          // console.log(list);
          this.searchList = this.searchTerm.length > 1 ? list : [];
        });
      if (this.$route.params.content !== "favourites") {
        this.$router.push("/user/shop");
      }
    },
    logOutClick() {
      this.$emit("logOutClicked", true);
    },
    toggleSidebar() {
      this.mobSearch = false;
      this.openNotifications = false;
      this.openSettings = false;
      // console.log("toggleSidebar");
      document.querySelector("body").classList.toggle("sidenav-toggled");
    },
    toggleNotifications() {
      this.mobSearch = false;
      this.openSettings = false;
      this.openNotifications = this.openNotifications === false ? true : false;
      document.querySelector("body").classList.remove("sidenav-toggled");
    },
    toggleSettings() {
      this.mobSearch = false;
      this.openNotifications = false;
      this.openSettings = this.openSettings === false ? true : false;
      document.querySelector("body").classList.remove("sidenav-toggled");
    },
    toggleSearch() {
      if (this.$route.params.content !== "favourites") {
        this.$router.push("/user/shop");
      }
      this.openNotifications = false;
      this.openSettings = false;
      this.mobSearch = this.mobSearch === false ? true : false;
      document.querySelector("body").classList.remove("sidenav-toggled");
    },
  },
};
</script>
