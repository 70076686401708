<template>
  <main class="app-content single-product">
    <div class="app-title">
      <div>
        <h1>Products</h1>
        <!-- <p>{{ user.firstname }} {{ user.lastname }} - {{ user.email }}</p> -->
      </div>
      <ul class="app-breadcrumb breadcrumb">
        <li class="breadcrumb-item">
          <a href="#/user/dashboard"><i class="fa fa-home fa-lg"></i></a>
        </li>
        <li class="breadcrumb-item">
          <a href="#/user/dashboard">Dashboard</a>
        </li>
        <li class="breadcrumb-item">
          <a href="javascript:;" onclick="history.back()">Coffee</a>
        </li>
        <li class="breadcrumb-item">{{ product.name }}</li>
      </ul>
    </div>
    <div class="app-title product-filter-nav flex-wrap">
      <ul class="product-filter">
        <li>
          <button class="btn btn-primary btn-filter" onclick="history.back()">
            <i class="fa fa-chevron-left" aria-hidden="true"></i> Back
          </button>
        </li>
        <li>
          <a
            v-if="user.admin > 0"
            class="btn-edit"
            :href="`#/user/shop/${product.slug}/editor`"
          >
            <button class="btn btn-info btn-block" type="button">Edit</button>
          </a>
        </li>
        <!-- <li>
          <a
            v-if="user.admin > 0"
            class="btn-edit"
            :href="`#/user/shop/${product.slug}/editor`"
          >
            <button class="btn btn-warning btn-block" type="button">
              Delete
            </button>
          </a>
        </li> -->
      </ul>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div :class="`tile full ${getFeature(product.feature)}`" v-if="product">
          <div class="row">
            <div class="col-sm-8 mb-2">
              <div
                :class="`cat cat-title ${
                  product.catagory === 'Core +' ? 'CorePlus' : product.catagory
                }`"
              >
                <img
                  class="leaf"
                  :src="`/img/${getPath}.png`"
                  :alt="`Upstream ${product.catagory}`"
                />
                <div>
                  <h2 class="mb-1" id="typography">
                    {{ product.name }}
                  </h2>
                  <h4 class="mb-0" id="typography">
                    {{ product.country }}
                  </h4>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-6 mb-2">
                  <div class="details-item">
                    <img class="icon-title" src="/img/icon-region.svg" alt="" />
                    <span>{{ product.region }}</span>
                  </div>
                  <div class="details-item">
                    <img
                      class="icon-title"
                      src="/img/icon-producer.svg"
                      alt=""
                    />
                    <span>{{ product.producer }}</span>
                  </div>
                  <div class="details-item">
                    <img
                      class="icon-title"
                      src="/img/icon-process.svg"
                      alt=""
                    />
                    <span>{{ product.process }}</span>
                  </div>
                </div>
                <div class="col-sm-6 mb-2">
                  <div class="details-item">
                    <img
                      class="icon-title"
                      src="/img/icon-variety.svg"
                      alt=""
                    />
                    <span>{{ product.variety }}</span>
                  </div>
                  <div class="details-item">
                    <img
                      class="icon-title"
                      src="/img/icon-altitude.svg"
                      alt=""
                    />
                    <span>{{ product.altitude }}</span>
                  </div>
                  <div v-if="product.cropdate" class="details-item">
                    <img
                      class="icon-title"
                      src="/img/icon-harvest.svg"
                      alt=""
                    />
                    <span>{{ displayDate(product.cropdate) }}</span>
                  </div>
                  <div v-if="product.bag" class="details-item">
                    <img class="icon-title" src="/img/icon-bag.svg" alt="" />
                    <span>{{ product.bag }}</span>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col mb-2">
                  <div class="details-item">
                    <img
                      class="icon-title"
                      src="/img/icon-flavour.svg"
                      alt=""
                    />
                    <span>{{ product.cuppingnotes }}</span>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col mb-4">
                  <div class="farm-info">
                    <div v-html="farmtext"></div>
                    <div class="buttons">
                      <button
                        class="btn btn-primary readMore"
                        @click="readMore"
                      >
                        <span>Read more </span>
                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                      </button>
                      <button
                        class="btn btn-primary readMore pdf-spiner"
                        @click="createHtmlPdf()"
                        :disabled="ready"
                      >
                        <span>Download Product PDF</span>
                        <i
                          v-if="ready"
                          class="fa fa-spinner"
                          aria-hidden="true"
                        ></i>
                        <i v-else class="fa fa-download" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col mb-4">
                  <div class="farm-info"></div>
                </div>
              </div>

              <div v-if="product.labnotes" class="row">
                <div class="col mb-4">
                  <div class="lab-info">
                    <div v-html="product.labnotes"></div>
                  </div>
                </div>
              </div>
              <!---->
              <div class="row">
                <div class="col-4 mb-4">
                  <button
                    class="mb-2 btn btn-outline-sample sample"
                    type="button"
                    @click="
                      addToCart(
                        product._id,
                        product.name,
                        product.slug,
                        getImage(product.images),
                        product.productcode,
                        product.country,
                        `sample`
                      )
                    "
                  >
                    Sample
                    <i class="app-menu__icon fa fa-shopping-basket"></i>
                  </button>
                </div>
                <div class="col-4 mb-4">
                  <button
                    class="mb-2 btn btn-outline-quote quote"
                    type="button"
                    @click="
                      addToCart(
                        product._id,
                        product.name,
                        product.slug,
                        getImage(product.images),
                        product.productcode,
                        product.country,
                        `quote`
                      )
                    "
                  >
                    Quote
                    <i class="app-menu__icon fa fa-file-text-o"></i>
                  </button>
                </div>
                <div class="col-4 mb-4">
                  <button
                    class="mb-2 btn btn-outline-dark quote"
                    type="button"
                    @click="
                      addToCart(
                        product._id,
                        product.name,
                        product.slug,
                        getImage(product.images),
                        product.productcode,
                        product.country,
                        `order`
                      )
                    "
                  >
                    Order Enquiry
                    <i class="app-menu__icon fa fa-truck"></i>
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col mb-4">
                  <h3>Quick enquiry</h3>
                  <p>Contact your Upstream rep with any questions.</p>
                  <tiptap
                    :userid="user._id"
                    :account="user._id"
                    :list="'user'"
                    :csrf="csrf"
                    :tabindex="2"
                    :subject="`Product enquire - ${product.productcode} ${product.name}`"
                    @emitSendMessage="sent"
                    :ui="false"
                  />
                </div>
              </div>
              <!---->
            </div>
            <div class="col-sm-4 mb-2">
              <div class="img">
                <swiper
                  v-if="imageList.length > 1"
                  :options="{
                    autoplay: {
                      delay: 5000,
                    },
                  }"
                  :slides-per-view="1"
                  :navigation="true"
                  :pagination="{ clickable: true }"
                  @swiper="onSwiper"
                  @slideChange="onSlideChange"
                >
                  <swiper-slide
                    v-for="(image, index) in imageList"
                    :key="index"
                  >
                    <img :src="`/img/products/${image.trim()}`" alt="" />
                  </swiper-slide>
                  <!-- <Navigation /> -->
                  <!-- <Pagination /> -->
                </swiper>
                <img
                  v-else
                  :src="`/img/products/${product.productcode}.jpg`"
                  alt=""
                />
              </div>
              <div
                :class="`cat ${
                  product.catagory === 'Core +' ? 'CorePlus' : product.catagory
                }`"
              >
                <!-- <img
                  class="leaf"
                  :src="`/img/${getPath}.png`"
                  :alt="`Upstream ${product.catagory}`"
                /> -->
              </div>
              <div class="tile-body tile-country">
                <!-- <Brazil /> -->
                <!-- <Country v-if="true" /> -->
                <!-- <h1 v-else>None</h1> -->
                <component
                  :is="comp"
                  v-if="component"
                  :country="product.country"
                  :region="product.region"
                ></component>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ProductPdf
      v-if="ready"
      :show="false"
      :file="file"
      @completedPDF="resetPDF"
    />
  </main>
  <transition name="slide">
    <div v-if="farmOpen" class="app-content filter-draw slide-card">
      <div class="slide-scroll">
        <div
          class="slide-head"
          :style="`background: url(img/head${
            Math.floor(Math.random() * 12) + 1
          }.jpg) no-repeat center center; background-size: cover;`"
        >
          <div class="slide-overlay">
            <div>
              <h1>{{ product.name }}</h1>
              <h4>{{ product.producer }}</h4>
              <p>{{ product.country }} - {{ product.region }}</p>
              <button class="btn readMore" @click="readMore">
                <i class="fa fa-chevron-left" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="slide-body" v-html="product.farminformation"></div>
        <button class="btn btn-primary readMore" @click="readMore">
          <i class="fa fa-chevron-left" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </transition>
</template>
<script>
import { mapState } from "vuex";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/swiper-vue.esm.js";
import "swiper/swiper-bundle.min.css";
SwiperCore.use([Pagination, Navigation]);
import { gsap, Draggable } from "gsap/all";
gsap.registerPlugin(Draggable);

import Tiptap from "@/components/Tiptap.vue";
// import api from "@/lib/api";
// import Brazil from "@/components/maps/Brazil.vue";
import { defineAsyncComponent } from "vue";
import dayjs from "dayjs";

import ProductPdf from "@/components/ProductPdf.vue";

export default {
  name: "Content",
  components: {
    Tiptap,
    Swiper,
    SwiperSlide,
    Navigation,
    Pagination,
    dayjs,
    ProductPdf,
  },
  data() {
    const onSwiper = (swiper) => {
      console.log(swiper);
      // this.swiper.update();
    };
    const onSlideChange = (swiper) => {
      console.log("slide change");
      console.log(swiper);
      swiper.update(); // This may need to be added to the Margin resize.
    };
    return {
      onSwiper,
      onSlideChange,
      component: "",
      product: {},
      heartsList: [],
      farmtext: "",
      farmOpen: false,
      imageList: [],
      getPath: "",
      file: "",
      ready: false,
    };
  },
  computed: {
    ...mapState(["products", "productSingle", "hearts", "user", "csrf"]),
    comp() {
      return defineAsyncComponent(() =>
        import(`@/components/maps/${this.getMap(this.component)}.vue`)
      );
    },
  },
  watch: {
    hearts() {
      this.heartsList = this.hearts;
    },
    products() {
      console.log("LOADED");
      const slug = this.$route.params.product;
      console.log(slug);
      this.$store.dispatch("getProduct", slug).then((result) => {
        console.log(result, this.productSingle);
        this.product = this.productSingle;
        this.farmtext = this.product.farminformation.substr(0, 200) + "...";
        this.imageList = this.product.images.split(",");
        if (this.product.catagory === "Top Shelf") {
          this.getPath = "top";
        } else if (this.product.catagory === "Core +") {
          this.getPath = "coreplus";
        } else {
          this.getPath = this.product.catagory.toLowerCase();
        }
        // this.getPath =
        //   this.product.catagory === "Top Shelf"
        //     ? "top"
        //     : this.product.catagory.toLowerCase();
        this.component = this.product.country.replace(" ", "");

        this.log("Product:#f60", "imageList", this.imageList);
      });
    },
  },
  mounted() {
    this.file = this.$route.params.product;
    window.scrollTo({ top: 0 });
    if (this.products.length > 1) {
      const slug = this.$route.params.product;
      console.log(slug);
      this.$store.dispatch("getProduct", slug).then((result) => {
        this.product = this.productSingle;
        this.farmtext = this.product.farminformation.substr(0, 200) + "...";
        this.imageList = this.product.images.split(",");
        if (this.product.catagory === "Top Shelf") {
          this.getPath = "top";
        } else if (this.product.catagory === "Core +") {
          this.getPath = "coreplus";
        } else {
          this.getPath = this.product.catagory.toLowerCase();
        }
        this.component = this.product.country.replace(" ", "");

        this.log("Product:#f60", "imageList", result);
        this.log("Product:#f60", "imageList", this.product);
      });
    }
  },
  methods: {
    resetPDF() {
      console.log("PDF Completed");
      setTimeout(() => {
        this.ready = false;
      }, 3000);
    },
    createHtmlPdf() {
      console.log("PDF", this.file);
      this.ready = true;
    },
    getMap(country) {
      this.log("Product:#f60", "This", country);
      if (["Brazil", "Colombia"].includes(country)) return "SouthAmerica";
      if (
        ["ElSalvador", "CostaRica", "Guatemala", "Panama", "Honduras"].includes(
          country
        )
      )
        return "CentralAmerica";
      if (["Ethiopia", "Rwanda", "Tanzania"].includes(country)) return "Africa";
      if (["India"].includes(country)) return "India";
      if (["Mexico"].includes(country)) return "Mexico";
      if (["Timor-Leste"].includes(country)) return "TimorLeste";
      if (["Indonesia"].includes(country)) return "Indonesia";
      if (["PapuaNew Guinea"].includes(country)) return "PapuaNewGuinea";
    },
    getFeature(label) {
      if (label === "Fresh Arrival") return "New";
      if (label === "Last Chance") return "Last";
      return "";
    },
    getImage(list) {
      return list.split(",")[0];
    },
    sent() {
      this.newSubject = "";
    },
    displayDate(rawDate) {
      return dayjs(rawDate).format("MMM / YYYY");
    },
    readMore() {
      document.querySelector("body").classList.toggle("scrolllocked");
      this.farmOpen = this.farmOpen === true ? false : true;

      const callback = (Xpercent) => {
        console.log(Xpercent);
        gsap.to(".filter-draw", {
          duration: 0.2,
          x: Xpercent > 50 ? "100%" : "0%",
          onComplete: () => {
            this.farmOpen = Xpercent > 50 ? false : true;
            if (Xpercent > 50)
              document.querySelector("body").classList.remove("scrolllocked");
          },
        });
      };

      setTimeout(() => {
        Draggable.create(".filter-draw", {
          edgeResistance: 1,
          type: "x",
          bounds: { minX: 0, maxX: window.innerWidth },
          onRelease: function () {
            this.enable();
            console.log("Drag enable");
          },
          onDragStart: function () {
            console.log("Drag start");
            this.enable();
          },
          // onDrag: function () {
          // console.log(this.x, this.y, this.rotation);
          // Xpercent = Math.round((this.x / window.innerWidth) * 100);
          // console.log(Xpercent);
          // },
          onDragEnd: function () {
            console.log("Drag end");
            let Xpercent = Math.round((this.x / window.innerWidth) * 100);
            console.log(Xpercent);
            callback(Xpercent);
          },
        });
      }, 501);
    },
    addToCart(
      prodId,
      prodName,
      prodSlug,
      prodImage,
      prodCode,
      prodCountry,
      prodType
    ) {
      // TODO: Seperate Add and Remove
      this.log("Product:#f60", "Add to cart", {
        prodId,
        prodName,
        prodSlug,
        prodCode,
        prodImage,
        prodCountry,
        prodType,
      });
      this.$store
        .dispatch("UPDATECARTINDB", {
          prodId,
          prodName,
          prodSlug,
          prodImage,
          prodCode,
          prodCountry,
          prodType,
          prodVariations: {
            weight: 50,
            roasted: true,
            bags: 5,
          },
        })
        .then((data) => {
          this.log("Product:#f60", "Cart added");
          // this.$store.dispatch("GETHEARTSINDB").then(() => {
          //   console.log("Fav heart list updated");
          // });
          let type = "Item";
          console.log("THIS", data.prodType);
          if (data.prodType === "sample") type = "Sample";
          if (data.prodType === "quote") type = "Quote";
          if (data.prodType === "order") type = "Order enquiry";

          this.success("Success", `${type} added to cart`);
        });
    },
  },
};
</script>
