<template>
  <div class="tile full favourites d-flex flex-column">
    <h3 class="tile-title">Favourites</h3>
    <table class="table">
      <tbody v-if="!user.hearts || user.hearts.length === 0">
        <tr>
          <td>
            <p class="text-center no-samples">You have no favourites.</p>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <!-- Start favs -->
        <tr v-for="(item, index) in heartsObjects.slice(0, 3)" :key="index">
          <td class="position-relative">
            <a class="" :href="`#/user/shop/${item.slug}`" @click="loadProduct">
              <img
                class="img"
                :src="`/img/products/${getImage(item.images)}`"
                alt=""
              />
            </a>
            <form
              class="heart item-heart"
              method="POST"
              action="/api/products/60d877d3b544814987e1ca91/remove-heart"
            >
              <input
                type="hidden"
                name="_csrf"
                value="VOJgDhvE-7BvZUb69dwntpOqYKPTO0IGZrpI"
              /><button type="submit" name="heart" @click.prevent="testErrors">
                <i class="fa fa-heart"></i>
              </button>
            </form>
          </td>
          <td>
            <div class="details">
              <div class="row">
                <div class="col-6">
                  <p>
                    <span>Name:</span>
                    <a
                      class=""
                      :href="`#/user/shop/${item.slug}`"
                      @click="loadProduct"
                      ><strong>{{ item.name }}</strong></a
                    >
                  </p>
                  <p><span>Country:</span>{{ item.country }}</p>
                </div>
                <div class="col-6">
                  <p><span>Category:</span>{{ item.catagory }}</p>
                  <p><span>Status:</span>{{ item.status || "TBC" }}</p>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <!-- End favs -->
      </tbody>
    </table>
    <!-- @click="testErrors" -->
    <a :href="`#/user/favourites`">
      <button class="btn btn-primary btn-block" type="button">
        See all favourites
      </button>
    </a>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "Content",
  components: {},
  data() {
    return {
      htmlmessage: "",
      heartListDisplay: [],
      file: "",
    };
  },
  computed: {
    ...mapState(["user", "heartsObjects"]),
    // ...mapGetters(["getHartsAsObject"]),
  },
  // watch: {
  //   products() {

  //   },
  // },
  mounted() {
    // this.$store.dispatch("GETHEARTSINDB").then(() => {
    //   console.log("Fav heart list updated");
    // });
  },
  methods: {
    getImage(list) {
      return list.split(",")[0];
    },
  },
};
</script>
