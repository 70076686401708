<template>
  <main class="app-content account-page">
    <div class="app-title">
      <div>
        <h1>
          <i class="fa fa-dashboard"></i>
          <span v-if="user.admin === 1"> Admin account management</span>
          <span v-if="user.admin === 2"> Super Admin account management</span>
          <span v-else> User Account Details</span>
        </h1>
        <p>Update your account details and password.</p>
      </div>
      <ul class="app-breadcrumb breadcrumb">
        <li class="breadcrumb-item">
          <a href="#/user/dashboard"
            ><a href="#/user/dashboard"><i class="fa fa-home fa-lg"></i></a
          ></a>
        </li>
        <li class="breadcrumb-item">
          <a href="#/user/dashboard">Dashboard</a>
        </li>
        <li class="breadcrumb-item">Account</li>
      </ul>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="tile">
          <h2 class="mb-3 line-head" id="typography">
            {{ accountid ? "Customer" : "User" }} Details
          </h2>
          <p class="text-muted">
            Please ensure your details are always up to date.
          </p>
          <form class="form" id="account-update">
            <table class="table">
              <tbody>
                <tr>
                  <td><h5>First name</h5></td>
                  <td>
                    <input
                      class="form-control"
                      id="InputFirstName"
                      type="text"
                      required=""
                      name="firstname"
                      aria-describedby=""
                      placeholder="First name"
                      v-model="account.firstname"
                    />
                  </td>
                </tr>
                <tr>
                  <td><h5>Last name</h5></td>
                  <td>
                    <input
                      class="form-control"
                      id="InputLastName"
                      type="text"
                      required=""
                      name="lastname"
                      aria-describedby=""
                      placeholder="Last name"
                      v-model="account.lastname"
                    />
                  </td>
                </tr>
                <tr>
                  <td><h5>Email</h5></td>
                  <td>
                    <input
                      class="form-control"
                      id="InputEmail"
                      type="email"
                      required=""
                      name="email"
                      aria-describedby=""
                      placeholder="Email"
                      v-model="account.email"
                    />
                  </td>
                </tr>
                <tr>
                  <td><h5>Phone</h5></td>
                  <td>
                    <input
                      class="form-control"
                      id="InputPhone"
                      type="phone"
                      required=""
                      name="phone"
                      aria-describedby=""
                      placeholder="Phone No."
                      v-model="account.phone"
                    />
                  </td>
                </tr>
                <tr>
                  <td><h5>Company</h5></td>
                  <td>
                    <input
                      class="form-control"
                      id="InputCompany"
                      type="company"
                      required=""
                      name="company"
                      aria-describedby=""
                      placeholder="Company name"
                      v-model="account.company"
                    />
                  </td>
                </tr>
                <tr>
                  <td><h5>Job title</h5></td>
                  <td>
                    <input
                      class="form-control"
                      id="InputJob"
                      type="job"
                      required=""
                      name="job"
                      aria-describedby=""
                      placeholder="Job title"
                      v-model="account.job"
                    />
                  </td>
                </tr>
                <!-- -->

                <tr v-if="!user.admin">
                  <td><h5>Street</h5></td>
                  <td>
                    <input
                      class="form-control"
                      id="InputStreet"
                      type="text"
                      required=""
                      name="street"
                      aria-describedby=""
                      placeholder="Street"
                      v-model="account.street"
                    />
                  </td>
                </tr>
                <tr v-if="!user.admin">
                  <td><h5>Suburb</h5></td>
                  <td>
                    <input
                      class="form-control"
                      id="InputState"
                      type="text"
                      required=""
                      name="suburb"
                      aria-describedby=""
                      placeholder="Suburb"
                      v-model="account.suburb"
                    />
                  </td>
                </tr>
                <tr v-if="!user.admin">
                  <td><h5>Postcode</h5></td>
                  <td>
                    <input
                      class="form-control"
                      id="InputPostcode"
                      type="text"
                      required=""
                      name="postcode"
                      aria-describedby=""
                      placeholder="Postcode"
                      v-model="account.postcode"
                    />
                  </td>
                </tr>
                <tr v-if="!user.admin">
                  <td><h5>State</h5></td>
                  <td>
                    <input
                      class="form-control"
                      id="InputState"
                      type="text"
                      required=""
                      name="state"
                      aria-describedby=""
                      placeholder="State"
                      v-model="account.state"
                    />
                  </td>
                </tr>

                <!-- -->

                <tr v-if="!accountid">
                  <td><h5>Notes</h5></td>
                  <td>
                    <textarea
                      class="form-control"
                      id="InputNotes"
                      type="notes"
                      cols="30"
                      rows="10"
                      required=""
                      name="notes"
                      aria-describedby=""
                      placeholder="Personal notes"
                      v-model="account.notes"
                    ></textarea>
                  </td>
                </tr>
              </tbody>
            </table>
            <button
              class="btn btn-block btn-success mb-3"
              id="save-account"
              type="submit"
              @click.prevent="updateAccountDetails"
            >
              Save account details
            </button>
          </form>
        </div>
      </div>
      <div class="col-lg-6">
        <div
          v-if="user.admin === 0 && Object.keys(accountmanager).length"
          class="tile"
        >
          <h2 class="mb-3 line-head" id="typography">Manager Contact</h2>
          <p class="text-muted">
            Your Upstream representative contact details.
          </p>
          <div v-if="accountmanager.hasOwnProperty('firstname')">
            <h5>
              {{ accountmanager.firstname }} {{ accountmanager.lastname }}
            </h5>
            <p>E: {{ accountmanager.email }}</p>
            <p>M: {{ accountmanager.phone }}</p>
            <!-- <button
              class="btn btn-block btn-success mb-3"
              type="button"
              @click="
                () => {
                  window.location = '#/user/notifications';
                }
              "
            >
              Send message
            </button> -->
          </div>
        </div>
        <div v-if="!accountid" class="tile">
          <h2 class="mb-3 line-head" id="typography">Password</h2>
          <p class="text-muted">
            Please ensure your details are always up to date.<br />
          </p>
          <form class="form" id="password-update">
            <table class="table">
              <tbody>
                <tr>
                  <td><h5>Old password</h5></td>
                  <td>
                    <input
                      class="form-control"
                      id="InputPassword"
                      type="password"
                      required=""
                      name="password"
                      aria-describedby=""
                      placeholder="Password"
                      v-model="credentials.password"
                    />
                  </td>
                </tr>
                <tr>
                  <td><h5>New password</h5></td>
                  <td>
                    <input
                      class="form-control"
                      id="InputNewPassword"
                      type="password"
                      required=""
                      name="new-password"
                      aria-describedby=""
                      placeholder="New password"
                      v-model="credentials.newpassword"
                    />
                  </td>
                </tr>
                <tr>
                  <td><h5>Confirm password</h5></td>
                  <td>
                    <input
                      class="form-control"
                      id="InputConfirmPassword"
                      type="password"
                      required=""
                      name="password-confirm"
                      aria-describedby=""
                      placeholder="Confirm password"
                      v-model="credentials.confirmnewpassword"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <button
              class="btn btn-block btn-warning mb-3"
              id="save-password"
              type="button"
              @click.prevent="updatePasswordAPI"
            >
              Update password
            </button>
          </form>
        </div>
        <!-- <div v-if="accountid" class="tile">
          <h2 class="mb-3 line-head" id="typography">Customer Admin records</h2>
          <p class="text-muted">
            Please ensure your details are always up to date.<br />
          </p>
          <form class="form" id="admin-details">
            <table class="table">
              <tbody>
                <tr>
                  <td><h5>Manager</h5></td>
                  <td>
                    <select
                      class="form-control"
                      id="manager"
                      v-model="account.accountmanager"
                      placeholder="select"
                    >
                      <option disabled value="">Please select one</option>
                      <option
                        v-for="(manager, index) in customers.filter(
                          (x) => x.admin !== 0
                        )"
                        :key="index"
                        :value="manager._id"
                      >
                        {{ manager.firstname }} {{ manager.lastname }}
                      </option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td><h5>Customer notes</h5></td>
                  <td>
                    <textarea
                      class="form-control"
                      id="InputNotes"
                      type="notes"
                      cols="30"
                      rows="10"
                      required=""
                      name="notes"
                      aria-describedby=""
                      placeholder="Personal notes"
                      v-model="account.notes"
                    ></textarea>
                  </td>
                </tr>
              </tbody>
            </table>
            <button
              class="btn btn-block btn-warning mb-3"
              id="save-password"
              type="button"
              @click.prevent="updateAccountDetails"
            >
              Update admin records
            </button>
          </form>
        </div> -->
      </div>
    </div>
  </main>
</template>
<script>
import api from "@/lib/api";
import Swal from "sweetalert2";
import { mapState, mapGetters } from "vuex";
export default {
  name: "Account",
  components: {},
  // props: {
  //   user: {
  //     type: Object,
  //   },
  // },
  data() {
    return {
      adminDetails: [],
      accountid: "",
      account: {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        company: "",
        job: "",
        notes: "",
      },
      credentials: {
        password: "",
        newpassword: "",
        confirmnewpassword: "",
      },
    };
  },
  computed: {
    ...mapState([
      "user",
      "csrf",
      "customers",
      "customer",
      "accountmanager",
      "admin",
    ]),
    ...mapGetters(["getCustomer"]),
  },
  mounted() {
    if (this.$route.params.user) {
      // This is looking for a user ID viewd from a manager.
      this.accountid = this.$route.params.user;
      setTimeout(() => {
        this.account = this.getCustomer(this.accountid);
      }, 100);
    } else {
      // This is looking as a users own ID.
      console.log(this.user);
      this.account = this.user;
    }
    // console.log(this.accountmanager);
    if (this.user.admin == 0 && !Object.keys(this.accountmanager).length) {
      this.$store.dispatch("GETADMINMANAGER").then((response) => {
        this.log("Customer:#06f", "Get Admin success", response);
      });
    }
  },
  methods: {
    resetFields() {
      this.credentials.password =
        this.credentials.newpassword =
        this.credentials.confirmnewpassword =
          "";
    },
    /*-- --*/
    updateAccountDetails() {
      const postData = {
        account: this.accountid ? this.account : {},
        customer: this.accountid ? true : false,
      };
      Swal.fire({
        title: "Are you sure?",
        text: "All account details will be saved.",
        icon: "warning",
        showDenyButton: true,
        showConfirmButton: true,
        denyButtonText: "No, cancel please",
        confirmButtonText: "Yes, save details",
        confirmButtonColor: "#218838",
      }).then((response) => {
        // console.log(response.isConfirmed);
        // console.log(response.isDenied);
        // console.log(response.isDismissed);
        if (response.isConfirmed) {
          this.$store
            .dispatch("saveUserAPI", postData)
            .then(() => {
              console.log("then");
              this.successSwal();
            })
            .catch((errors) => {
              console.log("Cannot update", errors);
              this.errorSwal();
            });
        } else {
          this.cancelledSwal();
        }
      });
    },
    updatePasswordAPI() {
      Swal.fire({
        title: "Are you sure?",
        text: "Your password will be updated.",
        icon: "warning",
        showDenyButton: true,
        showConfirmButton: true,
        denyButtonText: "No, cancel please",
        confirmButtonText: "Yes, save password",
        confirmButtonColor: "#218838",
      }).then((response) => {
        if (response.isConfirmed) {
          let data = {
            _id: this.user._id,
            ...this.credentials,
            _csrf: this.csrf,
          };
          console.log(data);
          api
            .post("/api/password-update", data)
            .then((response) => {
              console.log("Password updated", response);
              this.successSwal();
              this.resetFields();
            })
            .catch((errors) => {
              console.log("Cannot update password", errors);
              this.errorSwal();
              this.resetFields();
            });
        } else {
          this.cancelledSwal();
          this.resetFields();
        }
      });
    },
  },
};
</script>
