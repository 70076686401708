<template>
  <aside class="app-sidebar">
    <ul class="app-menu">
      <li v-if="user.admin > 0" class="admin">
        <a class="app-menu__item" href="#/user/dashboard" @click="toggleSidebar"
          ><i class="app-menu__icon fa fa-dashboard"></i
          ><span class="app-menu__label">Admin Dashboard</span></a
        >
      </li>
      <li v-if="user.admin == 2" class="admin">
        <a class="app-menu__item" href="#/user/admins" @click="toggleSidebar"
          ><i class="app-menu__icon fa fa-users"></i
          ><span class="app-menu__label">Admin List</span></a
        >
      </li>
      <li v-if="user.admin > 0" class="admin">
        <a class="app-menu__item" href="#/user/customers" @click="toggleSidebar"
          ><i class="app-menu__icon fa fa-users"></i
          ><span class="app-menu__label">Customer List</span></a
        >
      </li>
      <li v-if="!user.admin">
        <a class="app-menu__item" href="#/user/dashboard" @click="toggleSidebar"
          ><i class="app-menu__icon fa fa-dashboard"></i
          ><span class="app-menu__label">Dashboard</span></a
        >
      </li>
      <li v-if="!user.admin">
        <a class="app-menu__item" href="#/user/cart" @click="toggleSidebar">
          <i class="app-menu__icon fa fa-shopping-basket"></i>
          <span class="app-menu__label">Cart</span>
          <span
            v-if="user.cart && user.cart.length > 0"
            class="badge badge-pill badge-danger"
            >{{ user.cart.length }}</span
          >
        </a>
      </li>

      <li>
        <a
          class="app-menu__item"
          href="#/user/shop"
          @click="
            toggleSidebar();
            clearSearch('shop');
          "
          ><div class="app-menu__icon d-flex align-items-center">
            <svg
              version="1.1"
              id="beans"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 122.88 96.84"
              xml:space="preserve"
            >
              <path
                fill="#000"
                d="M31.96,0c14.08,0,26.03,12.61,30.29,30.11c-1.07,0.94-2.12,1.92-3.15,2.95c-9.36,9.36-15.11,20.63-16.82,31.26 c-1.2,7.41-0.44,14.53,2.38,20.54c-2.72,1.63-5.64,2.76-8.69,3.29c5.92-23.37,3.06-34.99-1.37-45.75 c-4.29-10.42-10.11-21.59-3.54-42.39C31.35,0.01,31.66,0,31.96,0L31.96,0z M115.57,26.95c12.48,12.48,8.59,36.61-8.69,53.89 c-15.95,15.95-37.73,20.49-50.8,11.29c20.71-12.34,26.9-22.58,31.38-33.32c4.33-10.4,8.12-22.42,27.47-32.47 C115.14,26.53,115.36,26.74,115.57,26.95L115.57,26.95z M53.98,90.46c-0.34-0.3-0.67-0.61-0.99-0.93 c-12.48-12.48-8.59-36.61,8.69-53.89c16.28-16.28,38.63-20.67,51.6-10.7C92.53,35.42,86.92,44.22,82.36,55.17 C78.08,65.43,73.45,78.58,53.98,90.46L53.98,90.46z M33.31,88.46c-0.45,0.03-0.9,0.04-1.35,0.04C14.31,88.5,0,68.69,0,44.25 C0,21.23,12.7,2.31,28.93,0.2c-7.27,22.08-5.01,32.27-0.5,43.23C32.66,53.72,38.68,66.29,33.31,88.46L33.31,88.46z"
              ></path>
            </svg>
          </div>
          <span class="app-menu__label">Coffee</span></a
        >
      </li>
      <li v-if="!user.admin">
        <a
          class="app-menu__item"
          href="#/user/favourites"
          @click="
            toggleSidebar();
            clearSearch('favourites');
          "
          ><i class="app-menu__icon fa fa-heart" aria-hidden="true"></i
          ><span class="app-menu__label">Favourites</span></a
        >
      </li>
      <li v-if="!user.admin">
        <a class="app-menu__item" href="#/user/history" @click="toggleSidebar">
          <i class="app-menu__icon fa fa-history"></i>
          <span class="app-menu__label">History</span>
        </a>
      </li>
      <li>
        <a class="app-menu__item" href="#/user/account" @click="toggleSidebar"
          ><i class="app-menu__icon fa fa-user-circle-o"></i
          ><span class="app-menu__label">Account details</span></a
        >
      </li>
      <li v-if="!user.admin">
        <a
          class="app-menu__item"
          href="#/user/notifications"
          @click="toggleSidebar"
          ><i class="app-menu__icon fa fa-bell"></i
          ><span class="app-menu__label">Notifications</span></a
        >
      </li>
      <li>
        <a class="app-menu__item" href="#" @click.prevent="logOutClick"
          ><i class="app-menu__icon fa fa-sign-out"></i
          ><span class="app-menu__label">Log out</span></a
        >
      </li>
    </ul>
  </aside>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "Sidebar",
  components: {},
  props: {
    // user: {
    //   type: Object,
    // },
    content: { type: String },
  },
  computed: {
    ...mapState(["user", "csrf"]),
    ...mapActions(["getProductsfilter"]),
  },
  data() {
    return {
      logout: false,
    };
  },
  mounted() {
    // if (window.screen.width < 992) {
    //   document.querySelector("body").classList.add("sidenav-toggled");
    // }
  },
  methods: {
    clearSearch(page) {
      // console.log("Called");
      this.$store.commit("saveFilterInStore", []);
      this.$store.dispatch("getProductsfilter", {
        term: "",
        page: page,
      });
    },
    toggleSidebar() {
      console.log("toggleSidebar");
      if (window.screen.width < 768) {
        document.querySelector("body").classList.remove("sidenav-toggled");
      }
    },
    logOutClick() {
      this.$emit("logOutClicked", true);
    },
    getContent(target) {
      this.$emit("updateContent", target);
    },
  },
};
</script>
