<template>
  <main class="app-content dashboard">
    <div class="app-title">
      <div>
        <h1>Dashboard</h1>
        <p v-if="user.admin > 0">Product and customer shortcuts.</p>
        <p v-if="user.admin === 0">
          Your Favourites, History and product shortcuts.
        </p>
      </div>
      <ul class="app-breadcrumb breadcrumb">
        <li class="breadcrumb-item">
          <a href="#/user/dashboard"><i class="fa fa-home fa-lg"></i></a>
        </li>
        <li class="breadcrumb-item">Dashboard</li>
      </ul>
    </div>
    <div class="row">
      <!-- <div class="col-sm-6 col-md-3">
        <div
          class="widget-small category coloured-icon"
          @click="quickViewShop('shop')"
        >
          <div class="img Imports">
            <img class="leaf" src="@/assets/imports-blk.png" alt="Core" />
          </div>
          <div class="info">
            <h4>Full Range</h4>
            <p>See our full coffee range.</p>
          </div>
          <i class="nav icon fa fa-chevron-right"></i>
        </div>
      </div> -->
      <div class="col-sm-6 col-md-3">
        <div
          class="widget-small category coloured-icon"
          @click="quickViewShop('staples')"
        >
          <div class="img Staples">
            <img class="leaf" src="@/assets/staples.png" alt="Staples" />
          </div>
          <div class="info">
            <!-- <h4>Staples</h4>
            <p>
              <span class="sm">
                Go to blend builders<br />
                Clear &amp; reliable strengths
              </span>
              See the range
            </p> -->
            <img class="leaf" src="@/assets/stat-staples.svg" alt="Staples" />
          </div>
          <i class="nav icon fa fa-chevron-right"></i>
        </div>
      </div>

      <div class="col-sm-6 col-md-3">
        <div
          class="widget-small category coloured-icon"
          @click="quickViewShop('core')"
        >
          <div class="img Core">
            <img class="leaf" src="@/assets/core.png" alt="Core" />
          </div>
          <div class="info">
            <!-- <h4>Core</h4>
            <p>
              <span class="sm">
                Essentials<br />
                Distinct sourcing practises<br />
                Celebrates consistency<br />
              </span>
              See the range
            </p> -->
            <img class="leaf" src="@/assets/stat-core.svg" alt="Core" />
          </div>
          <i class="nav icon fa fa-chevron-right"></i>
        </div>
      </div>

      <div class="col-sm-6 col-md-3">
        <div
          class="widget-small category coloured-icon"
          @click="quickViewShop('core +')"
        >
          <div class="img CorePlus">
            <img class="leaf" src="@/assets/coreplus.png" alt="Core" />
          </div>
          <div class="info">
            <!-- <h4>Core +</h4>
            <p>
              <span class="sm">
                Elevating the essentials<br />
                Intriguing process<br />
                Captivating flavours<br />
              </span>
              See the range
            </p> -->
            <img
              class="leaf"
              src="@/assets/stat-core-plus.svg"
              alt="Core Plus"
            />
          </div>
          <i class="nav icon fa fa-chevron-right"></i>
        </div>
      </div>

      <div class="col-sm-6 col-md-3">
        <div
          class="widget-small category coloured-icon"
          @click="quickViewShop('top shelf')"
        >
          <div class="img Top">
            <img class="leaf" src="@/assets/top.png" alt="Top" />
          </div>
          <div class="info">
            <!-- <h4>Top Shelf</h4>
            <p>
              <span class="sm">
                Interesting varietals<br />
                Intriguing processes<br />
                Impressive profiles<br />
              </span>
              See the range
            </p> -->
            <img
              class="leaf"
              src="@/assets/stat-top-shelf.svg"
              alt="Top Shelf"
            />
          </div>
          <i class="nav icon fa fa-chevron-right"></i>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <CustomerList v-if="user.admin > 0" />
        <FavouritesList v-if="user.admin === 0" />
      </div>
      <div class="col-sm-6">
        <HistoryList v-if="user.admin === 0" />
        <div v-if="testElements">
          <div class="tile">
            <h3 class="tile-title">Upload CSV</h3>

            <input
              type="file"
              ref="file"
              id="file"
              v-on:change="handleFileUpload()"
            />

            <button v-on:click="submitFile()">Submit</button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="testElements" class="row">
      <!-- <div class="col-sm-6">
        <div class="tile">
          <h3 class="tile-title">Upload CSV</h3>

          <input
            type="file"
            ref="file"
            id="file"
            v-on:change="handleFileUpload()"
          />

          <button v-on:click="submitFile()">Submit</button>
        </div>
      </div> -->
      <!-- <div class="col-sm-6">
        <div class="tile">
          <h3 class="tile-title">Test Email</h3>

          <button v-on:click="sendEmail()">Submit</button>
        </div>
      </div>
      <div class="col-sm-12">
        <div class="tile">
          <h3 class="tile-title">Download PDF</h3>
          <button v-on:click="createHtmlPdf()">Submit</button>
        </div>
      </div> -->
    </div>
  </main>
</template>
<script>
import api from "@/lib/api";
import { mapState } from "vuex";
import CustomerList from "@/components/DashCustomers.vue";
import FavouritesList from "@/components/DashFavourites.vue";
import HistoryList from "@/components/DashHistory.vue";
export default {
  name: "Content",
  components: {
    CustomerList,
    FavouritesList,
    HistoryList,
  },
  data() {
    return {
      htmlmessage: "",
      heartListDisplay: [],
      file: "",
      testElements: false,
    };
  },
  computed: {
    ...mapState(["products", "user", "csrf", "heartsObjects"]),
    // ...mapGetters(["getHartsAsObject"]),
  },
  watch: {
    user() {
      console.log(this.user.email);
      if (this.user.email === "admin@fewd.com.au") {
        this.testElements = true;
      }
    },
  },
  mounted() {
    this.$store.dispatch("GETHEARTSINDB").then(() => {
      console.log("Fav heart list updated");
    });
  },
  methods: {
    // createHtmlPdf() {
    //   console.log("PDF");
    //   this.$refs.html2Pdf.generatePdf();
    // },
    quickViewShop(selection) {
      if (selection !== "shop") {
        this.$store.commit("saveFilterInStore", [selection]);
        this.$store.commit("saveActiveFilters", [0, 1, 0, 0]);
      }
      window.location = "#/user/shop";
    },
    getImage(list) {
      return list.split(",")[0];
    },
    handleFileUpload() {
      console.log(this.$refs.file.files[0]);
      this.file = this.$refs.file.files[0];
    },
    submitFile() {
      let formData = new FormData();
      formData.append("file", this.file);
      api
        .post("/api/csv-upload?_csrf=" + this.csrf, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function () {
          console.log("SUCCESS!!");
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
    },
    sendEmail() {
      api
        .post("/api/email?_csrf=" + this.csrf, this.user)
        .then(function () {
          console.log("SUCCESS!!");
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
    },
    // testErrors() {
    //   api
    //     .get("/api/error")
    //     .then((response) => {
    //       console.log(response);
    //       // response.data.data
    //       this.success("Success", "Work in progress.");
    //     })
    //     .catch((error) => {
    //       console.log(error.response);
    //       this.error("Warning", error.response.data.error);
    //     });
    // },
    // sendMessage() {
    //   console.log(this.htmlmessage);
    // },
  },
};
</script>
