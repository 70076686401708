<template>
  <main class="app-content single-product">
    <div class="app-title">
      <div>
        <h1>Editor</h1>
        <!-- <p>{{ user.firstname }} {{ user.lastname }} - {{ user.email }}</p> -->
      </div>
      <ul class="app-breadcrumb breadcrumb">
        <li class="breadcrumb-item">
          <a href="#/user/dashboard"><i class="fa fa-home fa-lg"></i></a>
        </li>
        <li class="breadcrumb-item">
          <a href="#/user/dashboard">Dashboard</a>
        </li>
        <li class="breadcrumb-item">
          <a href="javascript:;" onclick="history.back()">Coffee</a>
        </li>
        <li class="breadcrumb-item">{{ product.name }}</li>
      </ul>
    </div>
    <div class="app-title product-filter-nav flex-wrap">
      <ul class="product-filter">
        <li>
          <button class="btn btn-primary btn-filter" onclick="history.back()">
            <i class="fa fa-chevron-left" aria-hidden="true"></i> Back
          </button>
        </li>
      </ul>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div :class="`tile full ${getFeature(product.feature)}`" v-if="product">
          <div class="row">
            <div class="col-sm-8 mb-2">
              <div
                :class="`cat cat-title ${
                  product.catagory === 'Core +' ? 'CorePlus' : product.catagory
                }`"
              >
                <!-- <img
                  class="leaf"
                  :src="`/img/${getPath}.png`"
                  :alt="`Upstream ${product.catagory}`"
                /> -->
                <div>
                  <h2 class="mb-1" id="typography" style="width: 100%">
                    <input
                      v-if="this.$route.params.product === 'new-product'"
                      type="text"
                      style="width: 100%"
                      v-model="product.name"
                      placeholder="Name"
                    />
                    <span v-else>{{ product.name }}</span>
                  </h2>
                  <h4 class="mb-0" id="typography" style="width: 100%">
                    <input
                      v-if="this.$route.params.product === 'new-product'"
                      type="text"
                      v-model="product.country"
                      placeholder="Country"
                      style="width: 100%"
                    />
                    <span v-else>{{ product.country }}</span>
                  </h4>
                  <input
                    v-if="this.$route.params.product === 'new-product'"
                    type="text"
                    v-model="product.productcode"
                    style="width: 100%; margin-top: 4px"
                    placeholder="Product Code"
                  />
                  <span v-else>{{ product.productcode }}</span>
                  <select
                    style="width: 100%; margin-top: 4px"
                    v-model="product.catagory"
                  >
                    <option value="">Select category</option>
                    <option
                      value="Staples"
                      :selected="product.catagory === `Staples`"
                    >
                      Staples
                    </option>
                    <option
                      value="Core"
                      :selected="product.catagory === `Core`"
                    >
                      Core
                    </option>
                    <option
                      value="Core +"
                      :selected="product.catagory === `Core +`"
                    >
                      Core &plus;
                    </option>
                    <option
                      value="Top Shelf"
                      :selected="product.catagory === `Top Shelf`"
                    >
                      Top Shlelf
                    </option>
                  </select>
                  <!-- <input
                    type="text"
                    v-model="product.status"
                    style="width: 100%; margin-top: 4px"
                    placeholder="Status"
                  /> -->

                  <select
                    style="width: 100%; margin-top: 4px"
                    v-model="product.status"
                  >
                    <option value="">Select status</option>
                    <option
                      value="Fresh Arrival"
                      :selected="product.status === `Fresh Arrival`"
                    >
                      Fresh Arrival
                    </option>
                    <option
                      value="New Arrival"
                      :selected="product.status === `New Arrival`"
                    >
                      New Arrival
                    </option>
                    <option
                      value="Last Chance"
                      :selected="product.status === `Last Chance`"
                    >
                      Last Chance
                    </option>
                    <option
                      value="Prev. Crop"
                      :selected="product.status === `Prev. Crop`"
                    >
                      Prev. Crop
                    </option>
                    <option
                      value="Discounted"
                      :selected="product.status === `Discounted`"
                    >
                      Discounted
                    </option>
                    <option
                      value="Landing Soon"
                      :selected="product.status === `Landing Soon`"
                    >
                      Landing Soon
                    </option>
                    <option
                      value="Available"
                      :selected="product.status === `Available`"
                    >
                      Available
                    </option>
                  </select>
                </div>
                <!-- <a class="btn-edit" :href="`#/user/shop/${product.slug}`">
                  <button class="btn btn-primary btn-block" type="button">
                    Back to product
                  </button>
                </a> -->
              </div>

              <div class="row">
                <div class="col-sm-6 mb-2">
                  <div class="details-item">
                    <img class="icon-title" src="/img/icon-region.svg" alt="" />
                    <input
                      type="text"
                      v-model="product.region"
                      placeholder="Region"
                    />
                  </div>
                  <div class="details-item">
                    <img
                      class="icon-title"
                      src="/img/icon-producer.svg"
                      alt=""
                    />
                    <input
                      type="text"
                      v-model="product.producer"
                      placeholder="Producer"
                    />
                  </div>
                  <div class="details-item">
                    <img
                      class="icon-title"
                      src="/img/icon-process.svg"
                      alt=""
                    />
                    <input
                      type="text"
                      v-model="product.process"
                      placeholder="Process"
                    />
                  </div>
                </div>
                <div class="col-sm-6 mb-2">
                  <div class="details-item">
                    <img
                      class="icon-title"
                      src="/img/icon-variety.svg"
                      alt=""
                    />
                    <input
                      type="text"
                      v-model="product.variety"
                      placeholder="Variety"
                    />
                  </div>
                  <div class="details-item">
                    <img
                      class="icon-title"
                      src="/img/icon-altitude.svg"
                      alt=""
                    />
                    <input
                      type="text"
                      v-model="product.altitude"
                      placeholder="Altitude"
                    />
                  </div>
                  <div class="details-item">
                    <img
                      class="icon-title"
                      src="/img/icon-harvest.svg"
                      alt=""
                    />
                    <!-- <span>{{ displayDate(product.date) }}</span> -->
                    <input
                      type="date"
                      v-model="product.cropdate"
                      placeholder="Harvest Date"
                    /><br />
                    <label style="margin: 2px 5px">Harvest Date</label>
                  </div>
                  <div class="details-item">
                    <img class="icon-title" src="/img/icon-bag.svg" alt="" />
                    <input
                      type="text"
                      v-model="product.bag"
                      placeholder="Bag"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col mb-2">
                  <div class="details-item">
                    <img
                      class="icon-title"
                      src="/img/icon-flavour.svg"
                      alt=""
                    />
                    <input
                      type="text"
                      v-model="product.cuppingnotes"
                      width="100%"
                      placeholder="Cupping Notes"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col mb-4">
                  <!-- <textarea
                    v-model="product.farminformation"
                    style="width: 100%; height: 500px"
                    placeholder="Farm Information"
                  ></textarea> -->
                  <tiptap
                    v-if="product.farminformation"
                    :csrf="csrf"
                    :tabindex="2"
                    @emitCopyUpdate="updatTextArea"
                    :ui="true"
                    :hidebutton="true"
                    :currentText="product.farminformation"
                  />
                  <tiptap
                    v-else
                    :csrf="csrf"
                    :tabindex="2"
                    @emitCopyUpdate="updatTextArea"
                    :ui="true"
                    :hidebutton="true"
                    :currentText="''"
                  />
                </div>
              </div>
              <h4>Hidden fields</h4>
              <div class="row">
                <div class="col-md-4 mb-2">
                  <input
                    type="date"
                    v-model="product.date"
                    style="width: 100%"
                  />
                  <label>Crop Date</label>
                </div>
                <div class="col-md-4 mb-2">
                  <input
                    type="text"
                    v-model="product.metatags"
                    style="width: 100%"
                    placeholder="Metatags"
                  />
                </div>
                <div class="col-md-4 mb-2">
                  <input
                    type="text"
                    v-model="product.feature"
                    style="width: 100%"
                    placeholder="Feature"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 mt-4 mb-2">
                  <button
                    class="mb-2 btn btn-danger sample"
                    type="button"
                    @click="deleteProductData()"
                  >
                    Delete product
                    <i class="app-menu__icon fa fa-trash"></i>
                  </button>
                </div>
                <div class="col-md-6 mt-4 mb-2">
                  <button
                    class="mb-2 btn btn-success sample"
                    type="button"
                    @click="saveProductData()"
                  >
                    Save product
                    <i class="app-menu__icon fa fa-save"></i>
                  </button>
                </div>
              </div>

              <div class="row">
                <div class="col mb-4">
                  <div class="farm-info"></div>
                </div>
              </div>

              <div v-if="product.labnotes" class="row">
                <div class="col mb-4">
                  <div class="lab-info">
                    <div v-html="product.labnotes"></div>
                  </div>
                </div>
              </div>
              <!---->

              <!---->
            </div>
            <div class="col-sm-4 mb-2">
              <div class="img">
                <swiper
                  v-if="imageList.length > 1"
                  :options="{
                    autoplay: {
                      delay: 5000,
                    },
                  }"
                  :slides-per-view="1"
                  :navigation="true"
                  :pagination="{ clickable: true }"
                  @swiper="onSwiper"
                  @slideChange="onSlideChange"
                >
                  <swiper-slide
                    v-for="(image, index) in imageList"
                    :key="index"
                  >
                    <img :src="`/img/products/${image.trim()}`" alt="" />
                  </swiper-slide>
                  <!-- <Navigation /> -->
                  <!-- <Pagination /> -->
                </swiper>
                <img
                  v-else
                  :src="`/img/products/${product.productcode}.jpg`"
                  alt=""
                />
              </div>
              <div
                :class="`cat ${
                  product.catagory === 'Core +' ? 'CorePlus' : product.catagory
                }`"
              >
                <!-- <img
                    class="leaf"
                    :src="`/img/${getPath}.png`"
                    :alt="`Upstream ${product.catagory}`"
                  /> -->
              </div>
              <input
                class="mb-4"
                type="text"
                v-model="product.images"
                style="width: 100%"
                placeholder="Images"
              />

              <div class="mb-5">
                <div v-bind="getRootProps()" class="myDropzone">
                  <input v-bind="getInputProps()" />
                  <p v-if="isDragActive">Drop the files here ...</p>
                  <p v-else>
                    Drag 'n' drop images here,<br />or click here to select
                  </p>
                </div>
                <!-- <button class="btn" @click="open">Select files</button> -->
              </div>

              <div class="tile-body tile-country">
                <!-- <Brazil /> -->
                <!-- <Country v-if="true" /> -->
                <!-- <h1 v-else>None</h1> -->
                <component
                  :is="comp"
                  v-if="product.country && component"
                  :country="product.country"
                  :region="product.region"
                ></component>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import { mapState } from "vuex";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/swiper-vue.esm.js";
import "swiper/swiper-bundle.min.css";
SwiperCore.use([Pagination, Navigation]);
import { gsap, Draggable } from "gsap/all";
gsap.registerPlugin(Draggable);
import { useDropzone } from "vue3-dropzone";
import Tiptap from "@/components/Tiptap.vue";
// import Brazil from "@/components/maps/Brazil.vue";
import { defineAsyncComponent } from "vue";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import ProductPdf from "@/components/ProductPdf.vue";

export default {
  name: "Content",
  components: {
    Tiptap,
    Swiper,
    SwiperSlide,
    Navigation,
    Pagination,
    dayjs,
    ProductPdf,
  },
  data() {
    const onDrop = (acceptFiles, rejectReasons) => {
      console.log("onDrop called");
      this.saveFiles(acceptFiles); // saveFiles as callback
      console.log(acceptFiles, rejectReasons);
    };
    const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop });
    const onSwiper = (swiper) => {
      console.log(swiper);
      // this.swiper.update();
    };
    const onSlideChange = (swiper) => {
      console.log("slide change");
      console.log(swiper);
      swiper.update(); // This may need to be added to the Margin resize.
    };
    return {
      onSwiper,
      onSlideChange,
      component: "",
      product: {},
      heartsList: [],
      farmtext: "",
      farmOpen: false,
      imageList: [],
      getPath: "",
      file: "",
      ready: false,
      blankProduct: {
        name: "",
        country: "",
        date: "",
        images: "",
        productcode: "",
        cropdate: "",
        status: "",
        catagory: "",
        region: "",
        producer: "",
        variety: "",
        process: "",
        altitude: "",
        cuppingnotes: "",
        farminformation: "",
        metatags: "",
        feature: "",
      },
      getRootProps,
      getInputProps,
      ...rest,
    };
  },
  computed: {
    ...mapState(["products", "productSingle", "hearts", "user", "csrf"]),
    comp() {
      return defineAsyncComponent(() =>
        import(`@/components/maps/${this.getMap(this.component)}.vue`)
      );
    },
  },
  watch: {
    hearts() {
      this.heartsList = this.hearts;
    },
    products() {
      console.log("LOADED");
      const slug = this.$route.params.product;
      console.log(slug);
      if (slug !== "new-product") {
        this.$store.dispatch("getProduct", slug).then((result) => {
          console.log(result, this.productSingle);
          this.product = this.productSingle;
          // this.farmtext = this.product.farminformation.substr(0, 200) + "...";
          if (this.product.images.length)
            this.imageList = this.product.images.split(",");
          this.getPath =
            this.product.catagory === "Top Shelf"
              ? "top"
              : this.product.catagory.toLowerCase();
          this.component = this.product.country.replace(" ", "");

          this.log("Product:#f60", "imageList", this.imageList);
        });
      } else {
        this.product = this.blankProduct;
      }
    },
  },
  mounted() {
    this.file = this.$route.params.product;
    window.scrollTo({ top: 0 });
    if (this.products.length > 1) {
      const slug = this.$route.params.product;
      console.log(slug);
      if (slug !== "new-product") {
        this.$store.dispatch("getProduct", slug).then((result) => {
          this.product = this.productSingle;
          // this.farmtext = this.product.farminformation.substr(0, 200) + "...";
          if (this.product.images.length)
            this.imageList = this.product.images.split(",");
          this.getPath =
            this.product.catagory === "Top Shelf"
              ? "top"
              : this.product.catagory.toLowerCase();
          this.component = this.product.country.replace(" ", "");

          this.log("Product:#f60", "imageList", result);
          this.log("Product:#f60", "imageList", this.product);
        });
      } else {
        this.product = this.blankProduct;
      }
      console.log(this.product);
    }
  },
  methods: {
    onChange(event) {
      console.log(event.target.value);
    },
    saveFiles(files) {
      const formData = new FormData(); // pass data as a form
      for (var x = 0; x < files.length; x++) {
        // append files as array to the form, feel free to change the array name\
        if (this.product.images != "") this.product.images += ",";
        this.product.images += files[x].name;
        formData.append("images[]", files[x]);
      }
      console.info(formData["images[]"]);

      this.$store
        .dispatch("UPLOADIMAGES", formData)
        .then((response) => {
          console.info(response);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // onDrop(acceptFiles, rejectReasons) {
    //   this.saveFiles(acceptFiles); // saveFiles as callback
    //   console.log(acceptFiles, rejectReasons);
    // },
    getMap(country) {
      this.log("Product:#f60", "This", country);
      if (["Brazil", "Colombia"].includes(country)) return "SouthAmerica";
      if (
        ["ElSalvador", "CostaRica", "Guatemala", "Panama", "Honduras"].includes(
          country
        )
      )
        return "CentralAmerica";
      if (["Ethiopia", "Rwanda", "Tanzania"].includes(country)) return "Africa";
      if (["India"].includes(country)) return "India";
      if (["Mexico"].includes(country)) return "Mexico";
      if (["Timor-Leste"].includes(country)) return "TimorLeste";
      if (["Indonesia"].includes(country)) return "Indonesia";
      if (["PapuaNew Guinea"].includes(country)) return "PapuaNewGuinea";
    },
    getFeature(label) {
      if (label === "Fresh Arrival") return "New";
      if (label === "Last Chance") return "Last";
      return "";
    },
    getImage(list) {
      return list.split(",")[0];
    },
    sent() {
      this.newSubject = "";
    },
    updatTextArea(text) {
      console.log(text);
      this.product.farminformation = text;
    },
    displayDate(rawDate) {
      return dayjs(rawDate).format("MMM / YYYY");
    },
    saveProductData() {
      console.log("Content saving", this.product);
      Swal.fire({
        title: "Are you sure?",
        text: "All product details will be saved.",
        icon: "warning",
        showDenyButton: true,
        showConfirmButton: true,
        denyButtonText: "No, cancel please",
        confirmButtonText: "Yes, save details",
        confirmButtonColor: "#218838",
      }).then((response) => {
        if (response.isConfirmed) {
          this.$store
            .dispatch("UPDATESINGLEPRODUCT", this.product)
            .then((response) => {
              console.log(response);
              this.getUpdatedProductList();
              this.successSwal();
            })
            .catch((errors) => {
              console.log("Cannot update", errors);
              this.errorSwal();
            });
        } else {
          this.cancelledSwal();
        }
      });
    },
    getUpdatedProductList() {
      this.$store.dispatch("GETSHOPPRODUCTS").then(() => {
        console.log("Products saved to store.");
      });
    },
    deleteProductData() {
      console.log("Content deleting", this.product);
      Swal.fire({
        title: "Delete coffee?",
        text: "Deleting this product can't be undone!",
        icon: "warning",
        showDenyButton: true,
        showConfirmButton: true,
        denyButtonText: "No, cancel please",
        confirmButtonText: "Yes, delete product",
        confirmButtonColor: "#218838",
      }).then((response) => {
        if (response.isConfirmed) {
          this.$store
            .dispatch("DELETESINGLEPRODUCT", this.product)
            .then((response) => {
              console.log(response);
              this.getUpdatedProductList();
              this.successSwal();
              window.location.href = "#/user/shop";
            })
            .catch((errors) => {
              console.log("Cannot update", errors);
              this.errorSwal();
            });
        } else {
          this.cancelledSwal();
        }
      });
    },
  },
};
</script>
<style>
.myDropzone {
  border: solid 1px #ccc;
  border-radius: 10px;
  padding: 15px;
  text-align: center;
  background: #eafbea;
  cursor: pointer;
}
</style>
