<template>
  <div class="tile full history d-flex flex-column">
    <h3 class="tile-title">Order history</h3>
    <table class="table">
      <tbody v-if="!user.history || user.history.length === 0">
        <tr>
          <td>
            <p class="text-center no-samples">You have no requests.</p>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr v-for="(item, index) in user.history.slice(0, 3)" :key="index">
          <td>
            <h5 class="text-left">
              Order: {{ ("00000" + item.number).slice(-5) }} -
              {{ item.date }}
            </h5>

            <span
              v-for="(product, index) in item.order"
              class="text-left"
              :key="index"
            >
              <span
                v-if="product.ordertype === 'sample'"
                class="badge badge-large badge-sample badge-bottom"
              >
                <i class="fa fa-gift" aria-hidden="true"></i> Sample
              </span>
              <span
                v-if="product.ordertype === 'quote'"
                class="badge badge-large badge-quote badge-bottom"
              >
                <i class="fa fa-file-text-o" aria-hidden="true"></i> Quote
              </span>
              <span
                v-if="product.ordertype === 'order'"
                class="badge badge-large badge-order badge-bottom"
              >
                <i class="fa fa-truck" aria-hidden="true"></i> Order
              </span>
              <p>{{ product.ordername }} - {{ product.ordercountry }}</p>
              <p v-if="product.ordertype === 'sample'">
                Weight {{ product.ordervariations.weight }} Grams -
                {{
                  product.ordervariations.roasted === true ? "Green" : "Roasted"
                }}
              </p>
              <p v-if="product.ordertype !== 'sample'">
                {{ product.ordervariations.bags }} kgs
              </p>
              <a :href="`#/user/shop/${product.orderslug}`">{{
                `View product - ${product.ordername}`
              }}</a>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <a :href="`#/user/history`">
      <button class="btn btn-primary btn-block" type="button">
        See all history
      </button>
    </a>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "Content",
  components: {},
  data() {
    return {
      htmlmessage: "",
      heartListDisplay: [],
      file: "",
    };
  },
  computed: {
    ...mapState(["user", "heartsObjects"]),
    // ...mapGetters(["getHartsAsObject"]),
  },
  // watch: {
  //   products() {

  //   },
  // },
  mounted() {
    // this.$store.dispatch("GETHEARTSINDB").then(() => {
    //   console.log("Fav heart list updated");
    // });
  },
  methods: {},
};
</script>
